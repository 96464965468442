import { ContactCard } from './ContactCard';
import { ContactGroup } from './ContactGroup';
import { Headline } from './Headline';
import { HeroSection } from './HeroSection';
import { Paragraph } from '../layout/Paragraph';
import { Section } from './Section';
import { Fragment, ReactElement } from 'react';

const ContactPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='Kontakte'>
      <Paragraph> Hier findet ihr alle notwendigen Kontakte, die ihr bei Fragen anschreiben könnt. </Paragraph>
      <Paragraph> Falls ihr euch nicht sicher seid, wer für euch der richtige Ansprechpartner ist, schreibt einfach eine E-Mail an kontakt@sv-heselwangen.de </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Vorstand' />
      <ContactGroup>
        <ContactCard
          name='Hartmut Landbeck'
          position='1. Vorstand'
          phone='0177 2146491'
          mail='vorstand@sv-heselwangen.de'
          thumbnailUrl='/contacts/Hartmut-Landbeck.jpg'
        />
        <ContactCard
          name='Joe Jetter'
          position='2. Vorstand'
          phone='07433 9032815'
          mail='vorstand@sv-heselwangen.de'
          thumbnailUrl='/contacts/Joe-Jetter.jpeg'
        />
        <ContactCard
          name='Marco Herter'
          position='Kassierer'
          phone='-'
          mail='kassierer@sv-heselwangen.de'
          thumbnailUrl='/contacts/Marco-Herter.jpg'
        />
      </ContactGroup>
    </Section>
    <Section>
      <Headline title='Abteilung Fußball' />
      <ContactGroup>
        <ContactCard
          name='David Gucwa'
          position='Abteilungsleiter Fußball'
          phone='0152 21807697'
          mail='fussball@sv-heselwangen.de'
          thumbnailUrl='/contacts/David-Gucwa.jpg'
        />
        <ContactCard
          name='Hans-Helmut Berszuck'
          position='Jugendleiter'
          phone='0170 6876242'
          mail='jugend@sv-heselwangen.de'
          thumbnailUrl='/contacts/Hansi-Berszuck.jpeg'
        />
        <ContactCard
          name='Dieter Schneider'
          position='AH Mannschaft'
          phone='0172 6226899'
          mail='-'
          thumbnailUrl='/shared/person-icon.png'
        />
      </ContactGroup>
    </Section>
    <Section className='dark'>
      <Headline title='Abteilung Gymnastik' />
      <ContactGroup>
        <ContactCard
          name='Heinz Günther'
          position='Herren Gymnastik'
          phone='07433 15216'
          mail='-'
          thumbnailUrl='/shared/person-icon.png'
        />
        <ContactCard
          name='Beate Bisinger'
          position='Fit Gymnastik'
          phone='0173 7771121'
          mail='-'
          thumbnailUrl='/shared/person-icon.png'
        />
      </ContactGroup>
    </Section>
    <Section>
      <Headline title='Sonstige' />
      <ContactGroup>
        <ContactCard
          name='Kevin Schuler'
          position='Webmaster'
          phone='-'
          mail='webmaster@sv-heselwangen.de'
          thumbnailUrl='/contacts/Kevin_Schuler.jpg'
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { ContactPage };
