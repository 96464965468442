import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

const StyledContactGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: center;
`;

interface ContactGroupProps {
  children: ReactNode;
}

const ContactGroup: FunctionComponent<ContactGroupProps> = ({ children }): ReactElement => (
  <StyledContactGroup>
    { children }
  </StyledContactGroup>
);

export { ContactGroup };
