import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

const StyledDownloadGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
  margin: auto;
  margin-bottom: 1.5rem;
`;

interface DownloadGroupProps {
  children: ReactNode;
}

const DownloadGroup: FunctionComponent<DownloadGroupProps> = ({ children }): ReactElement => (
  <StyledDownloadGroup>
    { children }
  </StyledDownloadGroup>
);

export { DownloadGroup };
