/* eslint-disable prefer-named-capture-group */
import { GalleryImageContainer } from '../../domain/GalleryImageContainer';
import { GalleryMetadataCollection } from '../../domain/GalleryMetadataCollection';

const AhWanderungContext = require.context('../../../public/galleries/trips/2021/ah-wanderung-2021/standard/', false, /\.(png|jpe?g|svg)$/u);

const basePath = '/galleries/trips/2021';

const getTripGalleries2021 = (): GalleryMetadataCollection => {
  const collection = {
    galleries: [
      {
        title: 'AH-Wanderung 2021',
        date: '17.09.2021 - 19.09.2021',
        imageCount: AhWanderungContext.keys().length,
        images: AhWanderungContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/ah-wanderung-2021/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/ah-wanderung-2021/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'ah-wanderung-2021',
        previewPath: `${basePath}/ah-wanderung-2021/thumbnails/timg_0012.jpg`
      }
    ],
    totalImages: 0
  };

  collection.totalImages = collection.galleries.reduce((imageCount, gallery): number => imageCount + gallery.imageCount, 0);

  return collection;
};

export { getTripGalleries2021 };
/* eslint-enable prefer-named-capture-group */
