import styled from 'styled-components';
import { FunctionComponent, ReactElement, useEffect, useRef } from 'react';

type WidgetType = 'table' | 'team-matches' | 'club-matches';

interface FussbaldeWidgetProps {
  elementId: string;
  type: WidgetType;
}

const WidgetContainer = styled.div`
  flex-grow: 1;
`;

const FussballdeWidgetNext: FunctionComponent<FussbaldeWidgetProps> = ({ elementId, type }): ReactElement => {
  const containerRef = useRef(document.createElement('div'));

  useEffect((): void => {
    const iframe = document.createElement('iframe');
    const iframeName = `fussballde_widget-${elementId}`;

    iframe.setAttribute('src', `https://next.fussball.de/widget/${type}/${elementId}`);
    iframe.setAttribute('name', iframeName);
    iframe.style.width = '100%';
    iframe.style.border = 'none';
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('scrolling', 'no');

    containerRef.current.appendChild(iframe);

    window.addEventListener('message', (event): void => {
      if (event.data.type === 'fussballde_widget:resize' && event.data.iframeName === iframeName) {
        iframe.style.height = `${event.data.height}px`;
      }
    });
  }, [ elementId, type ]);

  return (
    <WidgetContainer
      ref={ containerRef }
      className={ `fussballde_widget_${elementId}` }
      data-id={ elementId }
      data-type={ type }
    />
  );
};

export { FussballdeWidgetNext };
