import { ContactCard } from '../../../components/ContactCard';
import { ContactGroup } from '../../../components/ContactGroup';
import { FussballdeWidgetContainer } from '../../../components/FussballdeWidgetContainer';
import { FussballdeWidgetNext } from '../../../components/FussballdeWidgetNext';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { TeamPhoto } from '../../../components/TeamPhoto';
import { Fragment, ReactElement } from 'react';

const U13TeamPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='D-Jugend'>
      <Paragraph>
        Unsere D-Jugend spielt in der aktuellen Saison in der Qualistaffel 9. <br />
        Spielberechtigt sind die Jahrgänge 2013 und 2012.
      </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Tabelle und Begegnungen' />
      <FussballdeWidgetContainer>
        <FussballdeWidgetNext elementId='e42a26d0-0006-4e93-9eb0-b4c2d7d2687d' type='team-matches' />
        <FussballdeWidgetNext elementId='801b3a84-b60b-4f32-9bbb-ed029517a5b7' type='table' />
      </FussballdeWidgetContainer>
    </Section>
    <Section className='dark'>
      <Headline title='Kader' />
      <TeamPhoto src='/teams/u13/Mannschaftsfoto_2023.jpg' title='D-Jugend 2023/2024' />
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='Jan Stingel'
          position='Trainer'
          phone='-'
          mail='d-jugend@sv-heselwangen.de'
          thumbnailUrl='/shared/person-icon.png'
        />
        <ContactCard
          name='Roland Endörfer'
          position='Trainer'
          phone='-'
          mail='d-jugend@sv-heselwangen.de'
          thumbnailUrl='/shared/person-icon.png'
        />
        <ContactCard
          name='Semir Zaiane'
          position='Trainer'
          phone='-'
          mail='d-jugend@sv-heselwangen.de'
          thumbnailUrl='/shared/person-icon.png'
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { U13TeamPage };
