import { GalleryImageContainer } from '../../../domain/GalleryImageContainer';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { SRLWrapper } from 'simple-react-lightbox';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Fragment, FunctionComponent, ReactElement } from 'react';

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;

  img {
    max-width: 14vw;
  }
`;

interface GalleryProps {
  images: GalleryImageContainer[];
  title: string;
  date: string;
}

const Gallery: FunctionComponent<GalleryProps> = ({ images, title, date }): ReactElement => {
  const imageCount = images.length;

  return (
    <Fragment>
      <HeroSection title={ title }>
        <Paragraph> { date } </Paragraph>
        <Paragraph> { imageCount } Bilder </Paragraph>
      </HeroSection>
      <Section>
        <SRLWrapper>
          <ImageGrid>
            { images.map((image: GalleryImageContainer): any => (
              <a href={ image.imagePath } key={ uuidv4() }>
                <img src={ image.thumbnailPath } alt={ title } />
              </a>)) }
          </ImageGrid>
        </SRLWrapper>
      </Section>
    </Fragment>
  );
};

export { Gallery };
