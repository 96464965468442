import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { FunctionComponent, ReactElement } from 'react';

interface DownloadPreviewProps {
  preview: string;
  download: string;
  alt: string;
}

const StyledDownloadPreview = styled.div`
  position: relative;
  width: fit-content;
  box-shadow: ${(props): any => props.theme.boxShadows.raised};

  img {
    display: block;
    width: 100%;
    transition: .5s ease;

    :hover {
      opacity: .5;
    }
  }

  .overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    color: ${(props): any => props.theme.colors.hilight};
  }

  :hover .overlay {
    opacity: 1;
  }
`;

const DownloadPreview: FunctionComponent<DownloadPreviewProps> = ({ preview, download, alt }): ReactElement => (
  <StyledDownloadPreview>
    <a href={ download } target='_blank' rel='noreferrer'>
      <img src={ preview } alt={ alt } />
      <div className='overlay'>
        <FontAwesomeIcon icon={ faDownload } size='lg' />
      </div>
    </a>
  </StyledDownloadPreview>
);

export { DownloadPreview };
