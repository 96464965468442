import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FunctionComponent, ReactElement } from 'react';

const StyledTeamPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 20rem;
  max-width: 23rem;
  background-color: ${(props): any => props.theme.colors.secondary};
  color: ${(props): any => props.theme.colors.primary};
  text-align: left;
  padding-top: .8rem;
  box-shadow: ${(props): any => props.theme.boxShadows.raised};

  :hover {
    div.details {
      color: ${(props): any => props.theme.colors.primary};
    }
  }

  a {
    text-decoration: none;
  }
`;

const Title = styled.div`
  color: ${(props): any => props.theme.colors.hilight};
  padding-left: .8em;
  font-weight: 600;

  transition: color .2s ease-in-out;
`;

const Details = styled.div`
  transition: color .2s ease-in-out;
  color: ${(props): any => props.theme.colors.decent};
  padding-left: .8em;
  margin-bottom: -0.5em;
`;

const Thumbnail = styled.img`
  width: 100%;
  object-fit: contain;
  -webkit-clip-path: polygon(0 12%, 100% 0%, 100% 88%, 0 100%);
  clip-path: polygon(0 12%, 100% 0%, 100% 88%, 0 100%);
`;

interface TeamPreviewProps {
  title: string;
  ageClass: string;
  division: string;
  to: string;
  imgSrc: string;
}

const TeamPreview: FunctionComponent<TeamPreviewProps> = ({ title, ageClass, division, to, imgSrc }): ReactElement => (
  <StyledTeamPreview>
    <Link to={ to }>
      <Title>{ title }</Title>
      <Details className='details'>{ ageClass } | { division }</Details>
      <Thumbnail src={ imgSrc } alt={ title } />
    </Link>
  </StyledTeamPreview>
);

export { TeamPreview };
