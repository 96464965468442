import { Polaroid } from './Polaroid';
import styled from 'styled-components';
import { FunctionComponent, ReactElement } from 'react';

interface TeamPhotoProps {
  src: string;
  title: string;
}

const StyledTeamPhoto = styled.div`
  max-width: 800px;
  margin: auto;
`;

const TeamPhoto: FunctionComponent<TeamPhotoProps> = ({ src, title }): ReactElement => (
  <StyledTeamPhoto>
    <Polaroid src={ src } title={ title } />
  </StyledTeamPhoto>
);

export { TeamPhoto };
