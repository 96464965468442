import { GalleriesPage } from './GalleriesPage';
import { getEventGalleries2023 } from '../getEventGalleries2023';
import { ReactElement } from 'react';

const Events2023Page = (): ReactElement => {
  const galleryCollection = getEventGalleries2023();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Events 2023' />
  );
};

export { Events2023Page };
