import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

interface AccordionProps {
  children: ReactNode;
}

const StyledAccordion = styled.div`

`;

const Accordion: FunctionComponent<AccordionProps> = ({ children }): ReactElement => (
  <StyledAccordion>
    { children }
  </StyledAccordion>
);

export { Accordion };
