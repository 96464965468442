import { GalleriesPage } from './GalleriesPage';
import { getMatchGalleries202425 } from '../getMatchGalleries202425';
import { ReactElement } from 'react';

const Matches202425Page = (): ReactElement => {
  const galleryCollection = getMatchGalleries202425();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Saison 2023 / 2024' />
  );
};

export { Matches202425Page };
