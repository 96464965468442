import { Article } from '../../../domain/Article';
import { ArticlePreview } from './ArticlePreview';
import styled from 'styled-components';
import { FunctionComponent, ReactElement } from 'react';

const NewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
  gap: 2em;
  align-items: flex-start;
  margin-bottom: 1.5em;
`;

interface NewsProps {
  news: Article[];
}

const News: FunctionComponent<NewsProps> = ({ news }): ReactElement => (
  <NewsGrid>
    {news.map((article): ReactElement => (
      <ArticlePreview
        key={ 0 }
        title={ article.title }
        description={ article.description }
        date={ article.date }
        thumbnail={ article.thumbnail }
        to={ `/news/${article.routePath}` }
      />))}
  </NewsGrid>
);

export { News };
