import { GalleryPreviewMetadata } from '../../domain/GalleryPreviewMetadata';

const getLatestGalleries = (): GalleryPreviewMetadata[] => [
  {
    title: 'SVH : FC Burladingen (2:4)',
    date: '27.10.2024',
    routePath: 'galerie/spiele/2024/svh-burladingen-27-10-24',
    previewPath: `/galleries/matches/2024-2025/svh-burladingen-27-10-24/standard/img_0013.jpg`
  },
  {
    title: 'SVH : SGM Rosenfeld/Isingen/Brittheim (2:3)',
    date: '06.10.2024',
    routePath: 'galerie/spiele/2024/svh-rosenfeld-06-10-24',
    previewPath: `/galleries/matches/2024-2025/svh-rosenfeld-06-10-24/standard/img_0023.jpg`
  },
  {
    title: 'AH-Wanderung 2024',
    date: '20.09.2024 - 22.09.2024',
    routePath: 'galerie/ausfluege/2024/ah-wanderung',
    previewPath: `/galleries/trips/2024/ah-wanderung/standard/img_0060.jpg`
  }
];

export { getLatestGalleries };
