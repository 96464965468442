import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

const StyledGalleryTeaserGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
  gap: 2em;
  justify-content: center;
  max-width: 70vw;
  margin: auto;
`;

interface GalleryTeaserProps {
  children: ReactNode;
}

const GalleryTeaserGroup: FunctionComponent<GalleryTeaserProps> = ({ children }): ReactElement => (
  <StyledGalleryTeaserGroup>
    {children}
  </StyledGalleryTeaserGroup>
);

export { GalleryTeaserGroup };
