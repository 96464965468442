import { Headline } from './Headline';
import { Section } from './Section';
import { FunctionComponent, ReactElement } from 'react';

interface HeroSectionProps {
  title: string;
  children?: any;
}

const HeroSection: FunctionComponent<HeroSectionProps> = ({ title, children }): ReactElement => (
  <Section className='dark'>
    <Headline title={ title } />
    { children }
  </Section>
);

export { HeroSection };
