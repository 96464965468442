import { ContactCard } from './ContactCard';
import { ContactGroup } from './ContactGroup';
import { Headline } from './Headline';
import { HeroSection } from './HeroSection';
import { Paragraph } from '../layout/Paragraph';
import { Section } from './Section';
import styled from 'styled-components';
import { Fragment, ReactElement } from 'react';

const Image = styled.img`
  max-width: 50vw;
  margin-bottom: 1.5em;
`;

const FvSvhPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='Förderverein SV Heselwangen 1906 e.V.'>
      <Paragraph>
        Der Förderverein ist für die Organisation und Ausrichtung verschiedener Veranstaltungen rund um den SV Heselwangen zuständig.
      </Paragraph>
      <Paragraph>
        Der Großteil der Einnahmen wird regelmäßig an den Hauptverein gespendet um diesen unter anderem bei der Jugendarbeit zu unterstützen.
      </Paragraph>
      <Image src='/fvsvh/fvh-logo.png' alt='Logo Förderverein' />
    </HeroSection>
    <Section>
      <Headline title='Gründung' />
      <Paragraph>
        Der Förderverein des SV Heselwangen 1906 e.V. wurde am 02.11.2017 von 9 Mitgliedern des SV Heselwangen 1906 e.V. ins Leben gerufen.
      </Paragraph>
      <Image src='/fvsvh/Gruendungsfoto_FVH.jpg' alt='Gründungsfoto Förderverin' />
      <Paragraph>
        Die Gründungsmitglieder (v.l.n.r.): Matthias Kleiser, Kevin Schuler, Jens Fischer, David Nisch, Thomas Zeeb, Georg Weckerlein, Felix Eberhardt, Dieter Gomer, Isabel Kleiser
      </Paragraph>
    </Section>
    <Section className='dark'>
      <Headline title='Jetzt Mitglied werden!' />
      <Paragraph> Hast du Interesse dem Förderverein als aktives oder passives Mitglied beizutreten? </Paragraph>
      <Paragraph> Dann schnapp dir unseren <a href='/downloads/Mitgliedsantrag-FVSVH.pdf' target='_blank' rel='noreferrer'>Mitgliedsantrag</a> und wirf ihn ausgefüllt in unseren Briefkasten. </Paragraph>
    </Section>
    <Section>
      <Headline title='Kontakte' />
      <ContactGroup>
        <ContactCard name='Georg Weckerlein' position='1. Vorstand' phone='-' mail='vorstand.foerderverein@sv-heselwangen.de' thumbnailUrl='/shared/person-icon.png' />
        <ContactCard name='David Nisch' position='2. Vorstand' phone='-' mail='vorstand.foerderverein@sv-heselwangen.de' thumbnailUrl='/shared/person-icon.png' />
        <ContactCard name='Jens Fischer' position='Kassierer' phone='-' mail='kassierer.foerderverein@sv-heselwangen.de' thumbnailUrl='/shared/person-icon.png' />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { FvSvhPage };
