import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

interface GalleryTeaserItemProps {
  children: ReactNode;
  to: string;
}

const StyledGalleryTeaserItem = styled.div`
  a {
    text-decoration: none;
  }
`;

const GalleryTeaserItem: FunctionComponent<GalleryTeaserItemProps> = ({ children, to }): ReactElement => (
  <StyledGalleryTeaserItem>
    <Link to={ to }>
      { children }
    </Link>
  </StyledGalleryTeaserItem>
);

export { GalleryTeaserItem };
