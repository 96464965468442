import { GalleriesPage } from './GalleriesPage';
import { getEventGalleries2022 } from '../getEventGalleries2022';
import { ReactElement } from 'react';

const Events2022Page = (): ReactElement => {
  const galleryCollection = getEventGalleries2022();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Events 2022' />
  );
};

export { Events2022Page };
