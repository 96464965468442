import styled from 'styled-components';

interface StyledButtonProps {
  small: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  background-color: inherit;
   border: 1px solid ${(props): any => props.theme.colors.hilight};
  color: ${(props): any => props.theme.colors.decent};
  padding: .4em .8em;
  border-radius: 4px;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.08em;
  line-height: 1.4;
  transition: color .2s linear;

  ${(props): any => props.small && `
    font-size: .8rem;
  `}

  :hover {
    color: ${(props): any => props.theme.colors.hilight};
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export { StyledButton };
