import { DefaultTheme } from 'styled-components';
import '@fontsource/ubuntu';
import '@fontsource/covered-by-your-grace';

const Theme: DefaultTheme = {
  colors: {
    primary: '#FAFAFA',
    secondary: '#202020',
    hilight: '#d23c3c',
    decent: '#AAAAAA',
    pale: '#CCCCCC'
  },
  fonts: {
    default: '\'Source Sans Pro\', Arial, Helvetica, sans-serif',
    handwritten: 'Covered By Your Grace'
  },
  boxShadows: {
    raised: '0px 5px 5px -3px rgb(0 0 0 / 40%), 0px 8px 10px 1px rgb(0 0 0 / 28%), 0px 3px 14px 2px rgb(0 0 0 / 24%)'
  }
};

export {
  Theme
};
