import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

interface DivisionProps {
  title: string;
  children: ReactNode;
}

const StyledDivision = styled.div`
  margin-bottom: 2em;
`;

const DivisionHeadline = styled.div`
  margin-bottom: 1.2rem;
  font-size: 2rem;
  letter-spacing: 0.08em;
`;

const DivisionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
`;

const Division: FunctionComponent<DivisionProps> = ({ title, children }): ReactElement => (
  <StyledDivision>
    <DivisionHeadline>
      { title }
    </DivisionHeadline>
    <DivisionContent>
      { children }
    </DivisionContent>
  </StyledDivision>
);

export { Division };
