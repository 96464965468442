import { GalleryPreview } from './GalleryPreview';
import { GallerySectionOverview } from './GallerySectionOverview';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { Fragment, ReactElement } from 'react';

const GalleriesSectionPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='Galerie'>
      <Paragraph>Hier findet ihr Fotos von unseren Fußballspielen und diversen Veranstaltungen.</Paragraph>
      <Paragraph>Sämtliche Bilder auf unserer Seite sind urheberrechtlich geschützt. Die wiederveröffentlichung ist grundsätzlich untersagt und bedarf unserer ausdrücklichen Zustimmung.</Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Spielberichte' />
      <GallerySectionOverview>
        <GalleryPreview
          title='2024 / 2025'
          to='/galerie/spiele/2024'
          previewPath='/galleries/matches/2024-2025/svh-killertal-25-08-24/thumbnails/timg_0010.jpg'
          className='dark'
        />
        <GalleryPreview
          title='2023 / 2024'
          to='/galerie/spiele/2023'
          previewPath='/galleries/matches/2023-2024/svh-steinhofen-03-09-23/thumbnails/timg_0021.jpg'
          className='dark'
        />
        <GalleryPreview
          title='2022 / 2023'
          to='/galerie/spiele/2022'
          previewPath='/galleries/matches/2022-2023/svh-heuberg-01-09-22/thumbnails/timg_0010.jpg'
          className='dark'
        />
        <GalleryPreview
          title='2021 / 2022'
          to='/galerie/spiele/2021'
          previewPath='/galleries/matches/2021-2022/boll-svh-24-10-21/thumbnails/timg_0001.jpg'
          className='dark'
        />
      </GallerySectionOverview>
    </Section>
    <Section className='dark'>
      <Headline title='Veranstaltungen' />
      <GallerySectionOverview>
        <GalleryPreview
          title='2024'
          to='/galerie/events/2024'
          previewPath='/galleries/events/2024/mafu-cup-2024/thumbnails/timg_0066.jpg'
        />
        <GalleryPreview
          title='2023'
          to='/galerie/events/2023'
          previewPath='/galleries/events/2023/mafu-cup-2023-sonntag/thumbnails/timg_142.jpg'
        />
        <GalleryPreview
          title='2022'
          to='/galerie/events/2022'
          previewPath='/galleries/events/2022/elfmeter-turnier-2022/thumbnails/timg_0001.jpg'
        />
        <GalleryPreview
          title='2019'
          to='/galerie/events/2019'
          previewPath='/galleries/events/2019/elfmeter-turnier-2019/thumbnails/timg_0001.jpg'
        />
        <GalleryPreview
          title='2018'
          to='/galerie/events/2018'
          previewPath='/galleries/events/2018/elfmeter-turnier-2018/thumbnails/timg_0001.jpg'
        />
        <GalleryPreview
          title='2017'
          to='/galerie/events/2017'
          previewPath='/galleries/events/2017/elfmeter-turnier-2017/thumbnails/timg_0001.jpg'
        />
      </GallerySectionOverview>
    </Section>
    <Section>
      <Headline title='Ausflüge' />
      <GallerySectionOverview>
        <GalleryPreview
          title='2024'
          to='/galerie/ausfluege/2024'
          previewPath='/galleries/trips/2024/gartenschau-wangen/thumbnails/timg_0025.jpg'
          className='dark'
        />
        <GalleryPreview
          title='2023'
          to='/galerie/ausfluege/2023'
          previewPath='/galleries/trips/2023/ah-wanderung-2023/thumbnails/timg_0030.jpg'
          className='dark'
        />
        <GalleryPreview
          title='2022'
          to='/galerie/ausfluege/2022'
          previewPath='/galleries/trips/2022/ah-wanderung-2022/thumbnails/timg_0039.jpg'
          className='dark'
        />
        <GalleryPreview
          title='2021'
          to='/galerie/ausfluege/2021'
          previewPath='/galleries/trips/2021/ah-wanderung-2021/thumbnails/timg_0012.jpg'
          className='dark'
        />
      </GallerySectionOverview>
    </Section>
  </Fragment>
);

export { GalleriesSectionPage };
