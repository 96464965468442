import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const sidePadding = '.8rem';

const StyledNavLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  color: ${(props): any => props.theme.colors.primary};
  transition: color .2s linear;
  position: relative;
  padding: .3em ${sidePadding};

  :hover {
    color: ${(props): any => props.theme.colors.hilight};
  }

  ::before {
    content: '';
    position: absolute;
    border: none; /* Required to make border disappear instantly */
    width: 0;
    bottom: 0;
    left: ${sidePadding};
    transition: width .2s ease-out;
  }

  &:not(.nav-left):hover::before {
    border-bottom: 2px inset ${(props): any => props.theme.colors.hilight};
    width: calc(102% - 2*${sidePadding});
  }

  &.nav-left {
    margin-right: auto;
  }
`;

export { StyledNavLink };
