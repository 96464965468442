import { AgendaEvent } from '../domain/AgendaEvent';
import FullCalendar from '@fullcalendar/react';
import ListPlugin from '@fullcalendar/list';
import { FunctionComponent, ReactElement } from 'react';

interface YearsAgendaProps {
  events: AgendaEvent[];
}

const buttonTextInput = { today: 'Aktuell', listMonth: 'Monat', listYear: 'Jahr' };

const YearsAgenda: FunctionComponent<YearsAgendaProps> = ({ events }): ReactElement => (
  <FullCalendar
    plugins={ [ ListPlugin ] }
    headerToolbar={{
      left: 'prev,next today',
      center: 'title',
      right: 'listMonth listYear'
    }}
    buttonText={ buttonTextInput }
    initialView='listMonth'
    editable={ false }
    events={ events }
    locale='de'
    height='auto'
    noEventsContent='Für diesen Zeitraum sind noch keine Events geplant.'
    allDayContent='ganztägig'
  />
);

export { YearsAgenda };
