import { GalleriesPage } from './GalleriesPage';
import { getMatchGalleries202223 } from '../getMatchGalleries202223';
import { ReactElement } from 'react';

const Matches202223Page = (): ReactElement => {
  const galleryCollection = getMatchGalleries202223();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Saison 2022 / 2023' />
  );
};

export { Matches202223Page };
