import { Article } from '../../domain/Article';
import { ArticlePage } from './components/ArticlePage';
import { getAllNews } from './getNews';
import { HeroSection } from '../../components/HeroSection';
import { LinkButton } from '../../components/LinkButton';
import { News } from './components/News';
import { Paragraph } from '../../layout/Paragraph';
import { Section } from '../../components/Section';
import { Fragment, ReactElement, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const NewsPage = (): ReactElement => {
  const [ articles ] = useState<Article[]>(getAllNews());
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact={ true } path={ path }>
        <Fragment>
          <HeroSection title='Neuigkeiten'>
            <Paragraph>Hier findet ihr alle Berichte.</Paragraph>
          </HeroSection>
          <Section>
            <News news={ articles } />
            <LinkButton
              label='Zurück'
              uri='/aktuell'
              target='_self'
              small={ true }
            />
          </Section>
        </Fragment>
      </Route>
      {articles.map((article): ReactElement => (
        <Route path={ `${path}/${article.routePath}` } key={ 0 }>
          <ArticlePage
            key={ 0 }
            article={ article }
          />
        </Route>
      ))}
    </Switch>
  );
};

export { NewsPage };
