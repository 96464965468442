import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FunctionComponent, ReactElement } from 'react';

const StyledNews = styled.div`
  text-align: left;
  background-color: #101010;
  color: ${(props): any => props.theme.colors.primary};
  transition: all .2s ease-in-out;
  cursor: pointer;
  box-shadow: ${(props): any => props.theme.boxShadows.raised};

  :hover {
    span {
      color: ${(props): any => props.theme.colors.primary};
    }
  }

  img {
    width: 100%;
    display: block;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Container = styled.div`
  border-top: 2px solid ${(props): any => props.theme.colors.hilight};
  padding: 1em;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.div`
  font-size: .9rem;
  font-weight: 600;
  color: ${(props): any => props.theme.colors.hilight};
`;

const CardDescription = styled.p`
  font-size: .8rem;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props): any => props.theme.colors.decent};
  font-size: .6rem;
  text-transform: uppercase;

  margin-top: auto;

  span {
    transition: all .2s ease-in-out;
  }

  time {
    display: inline-flex;
  }
`;

interface NewsPreviewProps {
  title: string;
  description: string;
  date: string;
  thumbnail: string;
  to: string;
}

const ArticlePreview: FunctionComponent<NewsPreviewProps> = ({ title, description, date, thumbnail, to }): ReactElement => (
  <StyledNews>
    <Link to={ to }>
      <img src={ thumbnail } alt='thumbnail' />
      <Container>
        <CardTitle>{ title }</CardTitle>
        <CardDescription>
          { description }
        </CardDescription>
        <CardFooter>
          <time>{ date }</time>
          <span>
            Weiterlesen ...
          </span>
        </CardFooter>
      </Container>
    </Link>
  </StyledNews>
);

export { ArticlePreview };
