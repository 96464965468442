import { StyledButton } from '../layout/StyledButton';
import { FunctionComponent, ReactElement } from 'react';

interface LinkButtonProps {
  label: string;
  uri: string;
  target: '_self' | '_blank';
  small?: boolean;
}

// eslint-disable-next-line arrow-body-style
const LinkButton: FunctionComponent<LinkButtonProps> = ({ label, uri, target, small = false }): ReactElement => {
  return (
    <StyledButton type='button' small={ small }>
      <a href={ uri } target={ target } rel='noopener noreferrer'>
        { label }
      </a>
    </StyledButton>
  );
};

export { LinkButton };
