import { Accordion } from '../../layout/Accordion/Accordion';
import { AccordionItem } from '../../layout/Accordion/AccordionItem';
import { Headline } from '../../components/Headline';
import { HeroSection } from '../../components/HeroSection';
import { Paragraph } from '../../layout/Paragraph';
import { Section } from '../../components/Section';
import { Fragment, ReactElement } from 'react';

const FaqPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='FAQ'>
      <Paragraph> Ihr habt Fragen? Wir beantworten sie euch! </Paragraph>
      <Paragraph> Auf unserer FAQ-Seite findet ihr die wichtigsten Antworten zu häufig gestellten Fragen. </Paragraph>
      <Paragraph> Sollte danach noch etwas unklar sein dürft ihr euch gerne an unsere Kontakte wenden. </Paragraph>
    </HeroSection>

    <Section>
      <Headline title='Mitgliedschaft' />
      <Accordion>
        <AccordionItem header='Wann ist eine Mitgliedschaft notwendig?'>
          <Paragraph>
            Eine Mitgliedschaft ist notwendig, um am regelmäßigen Trainings- und Spielbetrieb sowie an vereinsinternen Ausflügen teilzunehmen.
            Ein Probetraining, Testspiel oder die Teilnahme an öffentlichen Veranstaltung des SVH setzt keine Mitgliedschaft voraus.
          </Paragraph>
        </AccordionItem>
        <AccordionItem header='Was kostet eine Mitgliedschaft?'>
          <Paragraph> Einzelbeitrag Aktive: 30,00 EUR pro Jahr </Paragraph>
          <br />
          <Paragraph> Einzelbeitrag Passive: 30,00 EUR pro Jahr </Paragraph>
          <br />
          <Paragraph> Einzelbeitrag Jugendliche: 25,00 EUR pro Jahr</Paragraph>
          <br />
          <Paragraph> Familienbeitrag: 50,00 EUR pro Jahr</Paragraph>
          <br />
          <Paragraph> Eltern- und Kind-Turnen: 30,00 EUR pro Jahr</Paragraph>
          <br />
          <Paragraph> (*Angaben ohne Gewähr. Verbindliche Preise sind dem Mitgliedsantrag zu entnehmen.) </Paragraph>
        </AccordionItem>
        <AccordionItem header='Wie kann ich Mitglied werden?'>
          <Paragraph> Bringe einfach den ausgefüllten Mitgliedsantrag zum nächsten Training mit oder werfe ihn in unseren Briefkasten am Hüttle. </Paragraph>
        </AccordionItem>
        <AccordionItem header='Welche Leistungen erhalte ich als Mitglied?'>
          <Paragraph>
            Die Mitgliedschaft ermöglicht dir die regelmäßige Teilnahme am Trainings- und Spielbetrieb sowie an vereinsinternen Ausflügen.
            Bei unseren Ausflügen ist es nicht unüblich, dass ein Teil der Kosten vom Verein übernommen wird.
            Desweiteren sind alle bei der Hauptversammlung anwesenden Mitglieder wahlberechtigt.
          </Paragraph>
        </AccordionItem>
        <AccordionItem header='Welche Pflichten habe ich als Mitglied?'>
          <Paragraph>
            Streng genommen besteht deine einzige Pflicht darin den Mitgliedsbeitrag zu bezahlen,
            doch ein Verein lebt vom Engagement seiner Mitglieder. Sei es nun die Aufrechterhaltung des Trainings- und Spielbetriebs
            oder die Planung und Durchführung von Ausflügen und Veranstaltungen.
            Das Ehrenamt in einem Verein ist sicherlich zeitaufwendig, macht aber auch Spaß und sowohl Verein als auch du als Mitglied profitieren davon.
          </Paragraph>
        </AccordionItem>
        <AccordionItem header='Wie kann ich meine Mitgliedschaft kündigen?'>
          <Paragraph>
            Du kannst deine Mitgliedschaft bei uns jederzeit (schriftlich) kündigen.
            Bringe dein Kündigungsschreiben entweder zum nächsten Training mit oder werfe es in unseren Briefkasten.
            Alternativ kannst du auch eine Email an vorstand@sv-heselwangen.de schreiben.
          </Paragraph>
        </AccordionItem>
      </Accordion>
    </Section>

    <Section>
      <Headline title='Trainings- und Spielbetrieb' />
      <Accordion>
        <AccordionItem header='Besteht die Möglichkeit für ein Probetraining?'>
          <Paragraph>
            Natürlich! Du kannst jederzeit ohne Anmeldung bei uns vorbeischauen. Egal ob Fußball, Gymnastik oder Eltern-Kind-Turnen.
            Im Zweifel halte aber vorher Rücksprache mit dem Trainerteam, ob an diesem Termin auch tatsächlich ein Training stattfinden.
          </Paragraph>
        </AccordionItem>
        <AccordionItem header='Kosten die Heimspiele des SVH Eintritt?'>
          <Paragraph>
            Für die Heimspiele der 1. Mannschaft verlangen wir ein Eintrittsgeld von 3,00 EUR.
            Die Spiele der 2. Mannschaft, sowie sämtliche Jugendspiele kosten in der Regel keinen Eintritt.
            In Ausnahmefällen (Finalspiele, Aufstiegsspiele, etc.) behalten wir uns aber vor auch dort Eintritt zu verlangen.
          </Paragraph>
        </AccordionItem>
      </Accordion>
    </Section>

    <Section>
      <Headline title='(Soziale) Medien' />
      <Accordion>
        <AccordionItem header='Wieso erhalte ich auf Social-Media-Plattformen keine Antwort auf meine Nachrichten?'>
          <Paragraph>
            Unsere Social-Media-Auftritte dienen einzig der Verbreitung von Medien und Informationen für unsere Fans.
            Über Feedback wie &quot;Likes&quot; und &quot;Kommentare&quot; freuen wir uns sehr, doch auf diese können wir leider nicht immer zeitnah reagieren.
            Für offizielle Anfragen schau bitte auf unsere Kontaktseite und wende dich per Email oder Telefon an die entsprechende Abteilung.
          </Paragraph>
        </AccordionItem>
        <AccordionItem header='Ich bin auf einem Foto abgebildet, das mir nicht gefällt. Was kann ich tun?'>
          <Paragraph>
            Grundsätzlich fallen unsere Bild- und Videoaufnahmen unter den Schutz von §22, §23 KUG.
            Desweiteren versuchen wir bei der Auswahl unserer Medien darauf zu achten, dass niemand unglücklich zur Schau gestellt wird.
            Sollte dennoch einmal der Fall eintreten, dass du ein Bild/Video von dir gelöscht haben möchtest, wende dich an unseren Webmaster.
            Im Einzelfall kommen wir dieser Bitte gerne nach.
          </Paragraph>
        </AccordionItem>
        <AccordionItem header='Ich möchte ein Foto/Video von euch verwenden. Darf ich das?'>
          <Paragraph>
            Alle von uns erstellten Aufnahmen sind urheberrechtlich geschützt.
            Eine Veröffentlichung durch Dritte ist untersagt und Bedarf unserer ausdrücklichen Zustimmung.
            Die Verwendung im privaten Bereich ist hingegen gestattet, d.h. du darfst Bilder/Videos herunterladen, um sie dir anzusehen oder Freunden und Verwandten zu zeigen.
          </Paragraph>
          <br />
          <Paragraph> Unseren Mitgliedern gestatten wir grundsätzlich die Verwendung von Bildern, auf denen sie persönlich im Vordergrund abgebildet sind, z.B. als Profilbild auf einer Social-Media-Plattform. </Paragraph>
        </AccordionItem>
      </Accordion>
    </Section>

    <Section>
      <Headline title='Sponsoring' />
      <Accordion>
        <AccordionItem header='Welche Sponsoring Möglichkeiten bietet der SVH an?'>
          <Paragraph>
            Der SVH bietet seinen Sponsoring-Partnern entweder Bandenwerbung oder Trikotwerbung an.
          </Paragraph>
        </AccordionItem>
        <AccordionItem header='Wie werde ich Sponsor?'>
          <Paragraph>
            Falls du Interesse an einem Sponsoring-Vertrag hast, wende dich bitte via E-Mail an unseren Sponsoring-Beauftragten
            Roberto Marzio:

            sponsoring@sv-heselwangen.de
          </Paragraph>
        </AccordionItem>
        <AccordionItem header='Wie kündige ich einen Sponsoring-Vertrag?'>
          <Paragraph>
            Wirf die (schriftliche) Kündigung entweder in unseren Briefkasten
            oder sende sie via Email an unseren Sponsoring-Beauftragten Roberto Marzio:

            sponsoring@sv-heselwangen.de
          </Paragraph>
        </AccordionItem>
        <AccordionItem header='Welche Vorteile hat ein Sponsoring-Vertrag für mich?'>
          <Paragraph>
            Ob Bandenwerbung oder Trikotwerbung. Der Sportplatz in Heselwangen ist ein viel besuchter Ort.
            An spitzen Tagen zählen wir weit über 100 Zuschauer. Desweiteren ist der Sportplatz anders als bei vielen anderen Vereinen nicht abseits am Waldrand gelegen,
            sondern befindet sich inmitten eines Wohngebietes in unmittelbarer Nähe des Einkaufs- und Ärztezentrums der Lisztstraße.
            Gerade für lokale Unternehmen der Ideale Ort um Werbung zu schalten.
          </Paragraph>
        </AccordionItem>
      </Accordion>
    </Section>

  </Fragment>
);

export { FaqPage };
