import { NavLink } from 'react-router-dom';
import { Section } from './Section';
import styled from 'styled-components';
import { Fragment, ReactElement } from 'react';

const TopContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1em;
  font-size: .8rem;

  a {
    color: ${(props): any => props.theme.colors.decent};
    text-decoration: none;

    :hover {
      color: ${(props): any => props.theme.colors.primary};
    }
  }
`;

const Division = styled.div`
  text-align: left;
  line-height: 1.4;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    cursor: pointer;
  }
`;

const BottomContent = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1em;
  font-size: .8rem;

  a {
    color: ${(props): any => props.theme.colors.secondary};
    text-decoration: none;

    :hover {
      color: ${(props): any => props.theme.colors.hilight};
    }
  }
`;

const Title = styled.div`
  color: ${(props): any => props.theme.colors.hilight};
`;

const IframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }
`;

const Footer = (): ReactElement => (
  <Fragment>
    <Section className='dark'>
      <TopContent>
        <Division>
          <Title>Externe Links</Title>
          <ul>
            <li>
              <a href='https://instagram.com/svheselwangen1906' target='blank' rel='noopener'>Instagram</a>
            </li>
            <li>
              <a href='https://www.facebook.com/SVHeselwangen/' target='blank' rel='noopener'>Facebook</a>
            </li>
            <li>
              <a href='https://www.youtube.com/channel/UCMt5dVN9lH3W6rc-OCr5z3w/featured' target='blank' rel='noopener'>Youtube</a>
            </li>
            <li>
              <a href='https://www.fussball.de/verein/sv-heselwangen-wuerttemberg/-/id/00ES8GNAVO00000BVV0AG08LVUPGND5I#!/' target='blank' rel='noopener'>Fussball.de</a>
            </li>
            <li>
              <a href='https://arag.de' target='blank' rel='noopener'>ARAG</a>
            </li>
          </ul>
        </Division>
        <Division>
          <Title>Downloads</Title>
          <ul>
            <li>
              <a href='/downloads/Mitgliedsantrag-SVH.pdf' target='_blank' rel='noreferrer'>Mitgliedsantrag SVH</a>
            </li>
            <li>
              <a href='/downloads/Mitgliedsantrag-FVSVH.pdf' target='_blank' rel='noreferrer'>Mitgliedsantrag Förderverein</a>
            </li>
            <li>
              <a href='/downloads/Jugendkonzept-SVH.pdf' target='_blank' rel='noreferrer'>Jugendkonzept</a>
            </li>
            <li>
              {/* <a href='/'>Satzung</a> */}
            </li>
          </ul>
        </Division>
        <Division>
          <Title>Hilfe</Title>
          <ul>
            <li>
              <NavLink to='/kontakte'> Kontakt </NavLink>
            </li>
            <li>
              <NavLink to='/faq'> FAQ </NavLink>
            </li>
          </ul>
        </Division>
        <Division>
          <Title>Anfahrt</Title>
          <IframeContainer>
            <iframe title='GoogleMap' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3277.903080671096!2d8.87591131600869!3d48.27367997923454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a06e7019ff291%3A0x3908f5f55fc9d494!2sB%C3%BCrgermeister-Jetter-Stra%C3%9Fe%2074%2C%2072336%20Balingen!5e1!3m2!1sde!2sde!4v1578816760782!5m2!1sde!2sde' width='400' height='200' allowFullScreen={ true } />
          </IframeContainer>
        </Division>
      </TopContent>
    </Section>
    <Section>
      <BottomContent>
        <Division>
          Copyright 2023 SV-Heselwangen 1906 e.V. Alle Rechte vorbehalten.
        </Division>
        <Division>
          <NavLink to='/impressum'> Impressum </NavLink>
        </Division>
        <Division>
          <NavLink to='/datenschutz'> Datenschutz </NavLink>
        </Division>
      </BottomContent>
    </Section>
  </Fragment>
);

export { Footer };
