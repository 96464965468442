/* eslint-disable prefer-named-capture-group */
import { GalleryImageContainer } from '../../domain/GalleryImageContainer';
import { GalleryMetadataCollection } from '../../domain/GalleryMetadataCollection';

const AhWanderungContext = require.context('../../../public/galleries/trips/2022/ah-wanderung-2022/standard/', false, /\.(png|jpe?g|svg)$/u);

const basePath = '/galleries/trips/2022';

const getTripGalleries2022 = (): GalleryMetadataCollection => {
  const collection = {
    galleries: [
      {
        title: 'AH-Wanderung 2022',
        date: '09.09.2022 - 11.09.2022',
        imageCount: AhWanderungContext.keys().length,
        images: AhWanderungContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/ah-wanderung-2022/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/ah-wanderung-2022/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'ah-wanderung-2022',
        previewPath: `${basePath}/ah-wanderung-2022/thumbnails/timg_0039.jpg`
      }
    ],
    totalImages: 0
  };

  collection.totalImages = collection.galleries.reduce((imageCount, gallery): number => imageCount + gallery.imageCount, 0);

  return collection;
};

export { getTripGalleries2022 };
/* eslint-enable prefer-named-capture-group */
