import { GalleriesPage } from './GalleriesPage';
import { getEventGalleries2018 } from '../getEventGalleries2018';
import { ReactElement } from 'react';

const Events2018Page = (): ReactElement => {
  const galleryCollection = getEventGalleries2018();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Events 2018' />
  );
};

export { Events2018Page };
