import { GalleriesPage } from './GalleriesPage';
import { getEventGalleries2019 } from '../getEventGalleries2019';
import { ReactElement } from 'react';

const Events2019Page = (): ReactElement => {
  const galleryCollection = getEventGalleries2019();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Events 2019' />
  );
};

export { Events2019Page };
