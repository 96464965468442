import { GalleriesPage } from './GalleriesPage';
import { getEventGalleries2024 } from '../getEventGalleries2024';
import { ReactElement } from 'react';

const Events2024Page = (): ReactElement => {
  const galleryCollection = getEventGalleries2024();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Events 2023' />
  );
};

export { Events2024Page };
