import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

const StyledTeamsOverview = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`;

interface TeamsOverviewProps {
  children: ReactNode;
}

const TeamsOverview: FunctionComponent<TeamsOverviewProps> = ({ children }): ReactElement => (
  <StyledTeamsOverview>
    { children }
  </StyledTeamsOverview>
);

export { TeamsOverview };
