import { ContactCard } from '../../../components/ContactCard';
import { ContactGroup } from '../../../components/ContactGroup';
import { FussballdeWidgetContainer } from '../../../components/FussballdeWidgetContainer';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { TeamPhoto } from '../../../components/TeamPhoto';
import { U15FixtureWidget } from './components/U15FixtureWidget';
import { U15TableWidget } from './components/U15TableWidget';
import { Fragment, ReactElement } from 'react';

const U15TeamPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='C-Jugend'>
      <Paragraph>
        Unsere C-Jugend spielt in der aktuellen Saison in der Qualigruppe 2. <br />
        Spielberechtigt sind die Jahrgänge 2010 und 2009.
      </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Tabelle und Begegnungen' />
      <FussballdeWidgetContainer>
        <U15FixtureWidget />
        <U15TableWidget />
      </FussballdeWidgetContainer>
    </Section>
    <Section className='dark'>
      <Headline title='Kader' />
      <TeamPhoto src='/teams/u15/Mannschaftsfoto.jpeg' title='C-Jugend 2021/2022' />
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='Alexander Wollbaum'
          position='Trainer'
          phone='-'
          mail='c-jugend@sv-heselwangen.de'
          thumbnailUrl='/shared/person-icon.png'
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { U15TeamPage };
