/* eslint-disable prefer-named-capture-group */
import { GalleryImageContainer } from '../../domain/GalleryImageContainer';
import { GalleryMetadataCollection } from '../../domain/GalleryMetadataCollection';

const MafuCupContext = require.context('../../../public/galleries/events/2024/mafu-cup-2024/standard/', false, /\.(png|jpe?g|svg)$/u);

const basePath = '/galleries/events/2024';

const getEventGalleries2024 = (): GalleryMetadataCollection => {
  const collection = {
    galleries: [
      {
        title: 'MAFU-Cup 2024',
        date: '02.02.2024 - 04.02.2024',
        imageCount: MafuCupContext.keys().length,
        images: MafuCupContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/mafu-cup-2024/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/mafu-cup-2024/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'mafu-cup-2024',
        previewPath: `${basePath}/mafu-cup-2024/thumbnails/timg_0001.jpg`
      }
    ],
    totalImages: 0
  };

  collection.totalImages = collection.galleries.reduce((imageCount, gallery): number => imageCount + gallery.imageCount, 0);

  return collection;
};

export { getEventGalleries2024 };
/* eslint-enable prefer-named-capture-group */
