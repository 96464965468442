import { GalleriesPage } from './GalleriesPage';
import { getTripGalleries2021 } from '../getTripGalleries2021';
import { ReactElement } from 'react';

const Trips2021Page = (): ReactElement => {
  const galleryCollection = getTripGalleries2021();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Ausflüge 2021' />
  );
};

export { Trips2021Page };
