import styled from 'styled-components';
import { FunctionComponent, ReactElement } from 'react';

interface StyledPolaroidProps {
  date?: string;
  sepiaTransition?: boolean;
}

const StyledPolaroid = styled.figure<StyledPolaroidProps>`
  margin: 0;
  height: fit-content;
  background-color: ${(props): any => props.theme.colors.primary};
  color: ${(props): any => props.theme.colors.secondary};
  padding: .8em;
  transition: all .2s linear;
  position: relative;
  box-shadow: ${(props): any => props.theme.boxShadows.raised};

  ::after {
    position: absolute;
    content: '${(props): any => props.date}';
    color: #fabc8e;
    right: 1.5rem;
    bottom: 6rem;
    width: 100%;
    height: 2rem;
    transition: all .2s linear;
    text-align: right;
    font-family: 'ubuntu';
  }

  :hover {
    img {
      filter: none;
    }

    ::after {
      color: #f58839;
    }
  }

  img {
    display: block;
    width: 100%;
    padding-bottom: 1em;

    ${(props): any => props.sepiaTransition && `
      filter: sepia(.8);
      transition: filter .3s ease-in-out;
    `}
  }

  figcaption {
    text-align: center;
    padding: .5em;
    font-family: ${(props): any => props.theme.fonts.handwritten};
    font-size: 1.5rem;
    height: 3rem;
    line-height: 1;
  }
`;

interface PolaroidProps {
  src: string;
  title: string;
  date?: string;
  sepiaTransition?: boolean;
}

const Polaroid: FunctionComponent<PolaroidProps> = ({ src, title, date, sepiaTransition }): ReactElement => (
  <StyledPolaroid date={ date } sepiaTransition={ sepiaTransition }>
    <img src={ src } alt='thumbnail' />
    <figcaption>
      { title }
    </figcaption>
  </StyledPolaroid>
);

export { Polaroid };
