import { ContactCard } from '../../../components/ContactCard';
import { ContactGroup } from '../../../components/ContactGroup';
import { FussballdeWidgetContainer } from '../../../components/FussballdeWidgetContainer';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { TeamPhoto } from '../../../components/TeamPhoto';
import { U17FixtureWidget } from './components/U17FixtureWidget';
import { U17TableWidget } from './components/U17TableWidget';
import { Fragment, ReactElement } from 'react';

const coachesPath = '/teams/u17/coaches';

const U17TeamPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='B-Jugend'>
      <Paragraph>
        Unsere B-Jugend spielt in der aktuellen Saison in der Kreisstaffel. <br />
        Spielberechtigt sind die Jahrgänge 2008 und 2007.
      </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Tabelle und Begegnungen' />
      <FussballdeWidgetContainer>
        <U17FixtureWidget />
        <U17TableWidget />
      </FussballdeWidgetContainer>
    </Section>
    <Section className='dark'>
      <Headline title='Kader' />
      <TeamPhoto src='/teams/u17/Mannschaftsfoto.jpg' title='B-Jugend 2022/2023' />
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='Joe Jetter'
          position='Trainer'
          phone='-'
          mail='b-jugend@sv-heselwangen.de'
          thumbnailUrl={ `${coachesPath}/Joe_Jetter.jpeg` }
        />
        <ContactCard
          name='Matthias Lorenz'
          position='Trainer'
          phone='-'
          mail='-'
          thumbnailUrl={ `${coachesPath}/Matthias_Lorenz.jpeg` }
        />
        <ContactCard
          name='Lennart Kelm'
          position='Trainer'
          phone='-'
          mail='-'
          thumbnailUrl={ `${coachesPath}/Lennart_Kelm.jpg` }
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { U17TeamPage };
