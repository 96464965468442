import { Article } from '../../../domain/Article';
import { DownloadGroup } from '../../../components/DownloadGroup';
import { DownloadPreview } from '../../../components/DownloadPreview';
import { ExternalLink } from '../../../domain/ExternalLink';
import { ExternalSiteTeaser } from '../../../components/ExternalSiteTeaser';
import { GalleryTeaserItem } from '../../../components/GalleryTeaserItem';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Polaroid } from '../../../components/Polaroid';
import { Section } from '../../../components/Section';
import styled from 'styled-components';
import { StyledButton } from '../../../layout/StyledButton';
import { useHistory } from 'react-router-dom';
import { Fragment, FunctionComponent, ReactElement } from 'react';

interface ArticlePageProps {
  article: Article;
}

const ImageGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 1.5rem;
  width: 80%;
  margin: auto;
`;

const ImageContainer = styled.div`
  width: 80%;
  margin: auto;
  margin-bottom: 1.5rem;

  img {
    max-width: 100%;
  }
`;

const GalleryTeaserContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2em;
  margin: auto;
  margin-bottom: 1.5rem;

  > div {
    max-width: 400px;
  }
`;

const ArticlePage: FunctionComponent<ArticlePageProps> = ({ article }): ReactElement => {
  const history = useHistory();

  return (
    <Fragment>
      <HeroSection title={ article.title }>
        <Paragraph> { article.description } </Paragraph>
      </HeroSection>
      <Section>
        {article.chapters.map((chapter): ReactElement => (
          // eslint-disable-next-line react/jsx-key
          <Fragment>
            <Headline title={ chapter.header } />
            {chapter.paragraphs.map((paragraph): ReactElement => {
              let renderedParagraph;

              switch (paragraph.type) {
                case 'text':
                  renderedParagraph = <Paragraph>{ paragraph.content }</Paragraph>;
                  break;
                case 'image':
                  renderedParagraph = (
                    <ImageContainer>
                      <img src={ paragraph.src } alt='' />
                    </ImageContainer>
                  );
                  break;
                case 'images':
                  renderedParagraph = (
                    <ImageGroup>
                      {paragraph.sources.map((src): ReactElement => (
                        // eslint-disable-next-line react/jsx-key
                        <ImageContainer>
                          <img src={ src } alt='' />
                        </ImageContainer>
                      ))}
                    </ImageGroup>
                  );
                  break;
                case 'downloads':
                  renderedParagraph = (
                    <DownloadGroup>
                      {paragraph.downloads.map((download): ReactElement => (
                        <DownloadPreview
                          key={ download.description }
                          preview={ download.preview }
                          download={ download.destination }
                          alt={ download.description }
                        />
                      ))}
                    </DownloadGroup>
                  );
                  break;
                case 'externalLinks':
                  renderedParagraph = (
                    <GalleryTeaserContainer>
                      {paragraph.links.map((externalLink: ExternalLink): ReactElement => (
                        <ExternalSiteTeaser to={ externalLink.destination } key={ externalLink.destination }>
                          <Polaroid
                            src={ externalLink.preview }
                            title={ externalLink.name }
                            sepiaTransition={ false }
                          />
                        </ExternalSiteTeaser>
                      ))}
                    </GalleryTeaserContainer>
                  );
                  break;
                default:
                  return <br />;
              }

              return renderedParagraph;
            })}
          </Fragment>
        ))}
        {
          article.galleryPreview &&
          <Fragment>
            <Headline title='Galerie' />
            <GalleryTeaserContainer>
              <GalleryTeaserItem to={ article.galleryPreview.routePath }>
                <Polaroid
                  src={ article.galleryPreview.previewPath }
                  title={ article.galleryPreview.title }
                  date={ article.galleryPreview.date }
                  sepiaTransition={ true }
                />
              </GalleryTeaserItem>
            </GalleryTeaserContainer>
          </Fragment>
        }
        {
          article.externalLink &&
          <Fragment>
            <Headline title={ article.externalLink.name } />
            <GalleryTeaserContainer>
              <ExternalSiteTeaser to={ article.externalLink.destination }>
                <Polaroid
                  src={ article.externalLink.preview }
                  title={ article.externalLink.name }
                  sepiaTransition={ false }
                />
              </ExternalSiteTeaser>
            </GalleryTeaserContainer>
          </Fragment>
        }
      </Section>
      <Section>
        <StyledButton small={ true } onClick={ (): void => history.goBack() }>
          Zurück
        </StyledButton>
      </Section>
    </Fragment>
  );
};

export { ArticlePage };
