/* eslint-disable prefer-named-capture-group */
import { GalleryImageContainer } from '../../domain/GalleryImageContainer';
import { GalleryMetadataCollection } from '../../domain/GalleryMetadataCollection';

const MafuCupSonntagContext = require.context('../../../public/galleries/events/2023/mafu-cup-2023-sonntag/standard/', false, /\.(png|jpe?g|svg)$/u);
const MafuCupSamstagContext = require.context('../../../public/galleries/events/2023/mafu-cup-2023-samstag/standard/', false, /\.(png|jpe?g|svg)$/u);
const MafuCupFreitagContext = require.context('../../../public/galleries/events/2023/mafu-cup-2023-freitag/standard/', false, /\.(png|jpe?g|svg)$/u);
const FussballcampContext = require.context('../../../public/galleries/events/2023/fussballcamp/standard/', false, /\.(png|jpe?g|svg)$/u);
const ElfmeterContext = require.context('../../../public/galleries/events/2023/11-meter-turnier/standard/', false, /\.(png|jpe?g|svg)$/u);

const basePath = '/galleries/events/2023';

const getEventGalleries2023 = (): GalleryMetadataCollection => {
  const collection = {
    galleries: [
      {
        title: '11-Meter-Turnier',
        date: '08.06.2023',
        imageCount: ElfmeterContext.keys().length,
        images: ElfmeterContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/11-meter-turnier/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/11-meter-turnier/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: '11-meter-turnier',
        previewPath: `${basePath}/11-meter-turnier/standard/img_0001.jpg`
      },
      {
        title: 'Fussballcamp 2023',
        date: '13.05.2023',
        imageCount: FussballcampContext.keys().length,
        images: FussballcampContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/fussballcamp/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/fussballcamp/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'fussballcamp',
        previewPath: `${basePath}/fussballcamp/standard/img_0001.jpg`
      },
      {
        title: 'MAFU-Cup 2023 Sonntag',
        date: '19.02.2023',
        imageCount: MafuCupSonntagContext.keys().length,
        images: MafuCupSonntagContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/mafu-cup-2023-sonntag/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/mafu-cup-2023-sonntag/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'mafu-cup-2023-sonntag',
        previewPath: `${basePath}/mafu-cup-2023-sonntag/thumbnails/timg_131.jpg`
      },
      {
        title: 'MAFU-Cup 2023 Samstag',
        date: '18.02.2023',
        imageCount: MafuCupSamstagContext.keys().length,
        images: MafuCupSamstagContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/mafu-cup-2023-samstag/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/mafu-cup-2023-samstag/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'mafu-cup-2023-samstag',
        previewPath: `${basePath}/mafu-cup-2023-samstag/thumbnails/timg_025.jpg`
      },
      {
        title: 'MAFU-Cup 2023 Freitag',
        date: '17.02.2023',
        imageCount: MafuCupFreitagContext.keys().length,
        images: MafuCupFreitagContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/mafu-cup-2023-freitag/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/mafu-cup-2023-freitag/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'mafu-cup-2023-freitag',
        previewPath: `${basePath}/mafu-cup-2023-freitag/thumbnails/timg_015.jpg`
      }
    ],
    totalImages: 0
  };

  collection.totalImages = collection.galleries.reduce((imageCount, gallery): number => imageCount + gallery.imageCount, 0);

  return collection;
};

export { getEventGalleries2023 };
/* eslint-enable prefer-named-capture-group */
