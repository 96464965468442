import { LinkButton } from '../../../components/LinkButton';
import { ReactElement } from 'react';

// eslint-disable-next-line arrow-body-style
const VisitFanshopButton = (): ReactElement => {
  return (
    <LinkButton
      label='Zum Fanshop'
      uri='https://meinvereinsfieber.de/collections/sv-heselwangen-1906-e-v'
      target='_blank'
    />
  );
};

export { VisitFanshopButton };
