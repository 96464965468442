import { GalleriesPage } from './GalleriesPage';
import { getMatchGalleries202324 } from '../getMatchGalleries202324';
import { ReactElement } from 'react';

const Matches202324Page = (): ReactElement => {
  const galleryCollection = getMatchGalleries202324();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Saison 2023 / 2024' />
  );
};

export { Matches202324Page };
