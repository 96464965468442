import { AgendaEvent } from '../../domain/AgendaEvent';

const getAgendaEvents = (): AgendaEvent[] => {
  const events: AgendaEvent[] = [
    { title: 'MAFU-Cup', start: '2024-02-02', end: '2024-02-05', allDay: true },
    { title: 'Hauptversammlung', start: '2024-03-22T20:00', end: '2024-03-22T23:00' },
    { title: 'Vatertagswanderung', start: '2024-05-09', allDay: true },
    { title: '11-Meter-Turnier', start: '2024-05-30', allDay: true },
    { title: 'Südtiroler Tag', start: '2024-07-28T11:00', end: '2024-07-28T20:00' },
    { title: 'AH-Wanderung', start: '2024-09-13', end: '2024-09-16', allDay: true },
    { title: 'Schlachtplatte', start: '2024-11-03T11:00', end: '2024-11-03T18:00' },
    { title: 'Nikolausmarkt Heselwangen', start: '2024-12-07T11:00', end: '2024-12-07T20:00' },
    { title: 'Weihnachtswanderung', start: '2024-12-28', allDay: true }
  ];

  return events;
};

export { getAgendaEvents };
