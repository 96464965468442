import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

interface GalleryTeaserItemProps {
  children: ReactNode;
  to: string;
}

const StyledGalleryTeaserItem = styled.div`
  a {
    text-decoration: none;
  }
`;

const ExternalSiteTeaser: FunctionComponent<GalleryTeaserItemProps> = ({ children, to }): ReactElement => (
  <StyledGalleryTeaserItem>
    <a href={ to } target='_blank' rel='noreferrer'>
      { children }
    </a>
  </StyledGalleryTeaserItem>
);

export { ExternalSiteTeaser };
