import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

interface DivisionItemProps {
  children: ReactNode;
}

const StyledDivisionItem = styled.div`
  max-width: 300px;
  flex-shrink: 0;
`;

const DivisionItem: FunctionComponent<DivisionItemProps> = ({ children }): ReactElement => (
  <StyledDivisionItem>
    { children }
  </StyledDivisionItem>
);

export { DivisionItem };
