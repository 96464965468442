import { ReactElement } from 'react';
import styled from 'styled-components';

const Background = styled.div`
  height: 27vw;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: url('/header-background.jpg');
    filter: contrast(0.5);
    background-position: 0px -21vw;
    background-repeat: no-repeat;
    background-size: cover;
  }

  ::after {
    content: '';
    position: absolute;
    height: 15%;
    width: 100%;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, rgba(0,0,0,0), rgba(0,0,0,0) 49%, ${(props): any => props.theme.colors.hilight} 50%, ${(props): any => props.theme.colors.secondary} 51%, ${(props): any => props.theme.colors.secondary});
  }
`;

const Header = function (): ReactElement {
  return (
    <Background />
  );
};

export { Header };
