import { Gallery } from './Gallery';
import { GalleryMetadata } from '../../../domain/GalleryMetadata';
import { GalleryMetadataCollection } from '../../../domain/GalleryMetadataCollection';
import { GalleryPreview } from './GalleryPreview';
import { GallerySectionOverview } from './GallerySectionOverview';
import { Headline } from '../../../components/Headline';
import { HeroSection } from '../../../components/HeroSection';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { FunctionComponent, ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

interface GalleryPageProps {
  galleryCollection: GalleryMetadataCollection;
  title: string;
}

const GalleriesPage: FunctionComponent<GalleryPageProps> = ({ galleryCollection, title }): ReactElement => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact={ true } path={ path }>
        <HeroSection title={ title }>
          <Paragraph> Galerien: { galleryCollection.galleries.length } | Fotos: { galleryCollection.totalImages } </Paragraph>
        </HeroSection>
        <Section>
          <Headline title='' />
          <GallerySectionOverview>
            {
              galleryCollection.galleries.map((gallery: GalleryMetadata): ReactElement => (
                <GalleryPreview
                  title={ gallery.title }
                  to={ `${url}/${gallery.routePath}` }
                  previewPath={ gallery.previewPath } key={ 0 }
                  className='dark'
                  date={ gallery.date }
                />
              ))
            }
          </GallerySectionOverview>
        </Section>
      </Route>
      {
        galleryCollection.galleries.map((gallery: GalleryMetadata): ReactElement => (
          <Route path={ `${path}/${gallery.routePath}` } key={ 0 }>
            <Gallery
              images={ gallery.images }
              title={ gallery.title }
              date={ gallery.date }
            />
          </Route>
        ))
      }
    </Switch>
  );
};

export { GalleriesPage };
