import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

const StyledSection = styled.section`
  background-color: ${(props): any => props.theme.colors.primary};
  color: ${(props): any => props.theme.colors.secondary};
  text-align: center;
  padding: 2em;

  @media (min-width: 1024px) {
    padding: 2em 6em;
  }

  &.dark {
    background-color: ${(props): any => props.theme.colors.secondary};
    color: ${(props): any => props.theme.colors.primary};
  }
`;

const Content = styled.div`
  max-width: 1920px;
  margin: auto;
`;

interface SectionProps {
  children: ReactNode;
  className?: string;
}

const Section: FunctionComponent<SectionProps> = function ({ children, className }): ReactElement {
  return (
    <StyledSection className={ className }>
      <Content>
        { children }
      </Content>
    </StyledSection>
  );
};

export { Section };
