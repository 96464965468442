import styled from 'styled-components';
import { Fragment, FunctionComponent, ReactElement, ReactNode, useState } from 'react';

interface AccordionItemProps {
  header: string;
  children: ReactNode;
}

const StyledButton = styled.button`
  width: 100%;
  text-align: left;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: inherit;
  
  box-shadow: 0px 2.5px 2.5px -1.5px rgb(0 0 0 / 20%), 0px 4px 5px .5px rgb(0 0 0 / 14%), 0px 1.5px 7px 1px rgb(0 0 0 / 12%);

  &.active, &:hover {
    color: ${(props): any => props.theme.colors.hilight};
  }

  transition: color .2s linear;
`;

const StyledContent = styled.div`
  text-align: left;
  padding: 1.5em 3em;
  overflow: hidden;
  background-color: ${(props): any => props.theme.colors.primary};
`;

const AccordionItem: FunctionComponent<AccordionItemProps> = ({ header, children }): ReactElement => {
  const [ active, setActive ] = useState(false);

  return (
    <Fragment>
      <StyledButton
        onClick={ (): void => setActive(!active) }
        className={ active ? 'active' : '' }
      >
        { header }
      </StyledButton>
      { active &&
      <StyledContent>
        { children }
      </StyledContent> }
    </Fragment>
  );
};

export { AccordionItem };
