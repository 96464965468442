import { GalleriesPage } from './GalleriesPage';
import { getTripGalleries2023 } from '../getTripGalleries2023';
import { ReactElement } from 'react';

const Trips2023Page = (): ReactElement => {
  const galleryCollection = getTripGalleries2023();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Ausflüge 2023' />
  );
};

export { Trips2023Page };
