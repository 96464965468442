import { Article } from '../domain/Article';
import { FussballdeWidgetContainer } from './FussballdeWidgetContainer';
import { FussballdeWidgetNext } from './FussballdeWidgetNext';
import { GalleryPreviewMetadata } from '../domain/GalleryPreviewMetadata';
import { GalleryTeaserGroup } from './GalleryTeaserGroup';
import { GalleryTeaserItem } from './GalleryTeaserItem';
import { getLatestGalleries } from '../app/home/getLatestGalleries';
import { getLatestNews } from '../app/news/getNews';
import { getTrainingEvents } from '../app/home/scheduleService';
import { Headline } from './Headline';
import { HeroSection } from './HeroSection';
import { LinkButton } from './LinkButton';
import { News } from '../app/news/components/News';
import { NewsletterRegistrationButton } from '../app/home/components/NewsletterRegistrationButton';
import { Paragraph } from '../layout/Paragraph';
import { Polaroid } from './Polaroid';
import { Section } from './Section';
import { Slideshow } from '../layout/Slideshow/Slideshow';
import styled from 'styled-components';
import { TrainingSchedule } from '../app/home/components/TrainingSchedule';
import { VisitFanshopButton } from '../app/home/components/VisitFanshopButton';
import { Fragment, ReactElement, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Postcard = styled.img`
  max-width: 600px;
  width: 100%;
  height: auto;
  margin-top: 1rem;
`;

const LandingPage = function (): ReactElement {
  const [ latestGalleries ] = useState<GalleryPreviewMetadata[]>(getLatestGalleries());
  const [ latestNews ] = useState<Article[]>(getLatestNews());

  return (
    <Fragment>
      <HeroSection title='Willkommen beim SVH!'>
        <Paragraph>Auf unserer Seite findet ihr die wichtigsten Informationen rund um unseren Verein.</Paragraph>
      </HeroSection>
      <Section>
        <Headline title='Aktuelles' />
        <News news={ latestNews } />
        <LinkButton
          label='Alle News Anzeigen'
          uri='/news'
          target='_self'
          small={ true }
        />
      </Section>
      <Section className='dark'>
        <Slideshow delay={ 5_000 }>
          <Fragment>
            <Headline title='Newsletter' />
            <Paragraph>Unser Newsletter erscheint regelmäßig am Monatsende, um dich über die aktuellen Ereignisse und bevorstehende Events zu informieren.</Paragraph>
            <NewsletterRegistrationButton />
          </Fragment>
          <Fragment>
            <Headline title='Fan-Kollektion' />
            <Paragraph>Jetzt Fan-Artikel durchstöbern und bequem von zu Hause aus bestellen!</Paragraph>
            <VisitFanshopButton />
          </Fragment>
        </Slideshow>
      </Section>
      <Section>
        <Headline title='Aktueller Spieltag' />
        <FussballdeWidgetContainer>
          <FussballdeWidgetNext elementId='cd281b02-2500-4666-897f-de8842b932d0' type='club-matches' />
        </FussballdeWidgetContainer>
      </Section>
      <Section className='dark'>
        <Headline title='Neueste Galerien' />
        <GalleryTeaserGroup>
          { latestGalleries.map((gallery): ReactElement => (
            // eslint-disable-next-line react/jsx-key
            <GalleryTeaserItem to={ gallery.routePath }>
              <Polaroid
                src={ gallery.previewPath }
                title={ gallery.title }
                date={ gallery.date }
                sepiaTransition={ true }
              />
            </GalleryTeaserItem>))}
        </GalleryTeaserGroup>
      </Section>
      <Section>
        <Headline title='Platzbelegung' />
        <TrainingSchedule events={ getTrainingEvents() } />
      </Section>
    </Fragment>
  );
};

export { LandingPage };
