import { ContactCard } from '../../../components/ContactCard';
import { ContactGroup } from '../../../components/ContactGroup';
import { Headline } from '../../../components/Headline';
import { Paragraph } from '../../../layout/Paragraph';
import { Section } from '../../../components/Section';
import { TeamPhoto } from '../../../components/TeamPhoto';
import { Fragment, ReactElement } from 'react';

const HobbyTeamPage = (): ReactElement => (
  <Fragment>
    <Section className='dark'>
      <Headline title='AH-Mannschaft' />
      <Paragraph>
        Unsere AH-Mannschaft ist eine reine Hobbymannschaft, bei der das gesellschaftliche Beisammensein im Vordergrund steht.
        Dennoch wird regelmäßig trainiert und an Freundschaftsspielen teilgenommen.
      </Paragraph>
    </Section>
    <Section>
      <Headline title='Trainingszeiten' />
      <Paragraph>
        Die AH-Mannschaft trifft sich jeden Freitag um 19:00 Uhr zum gemeinsamen Training in Heselwangen.
      </Paragraph>
    </Section>
    <Section className='dark'>
      <Headline title='Kader' />
      <TeamPhoto src='/teams/hobby/Mannschaftsfoto.jpg' title='AH-Mannschaft' />
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='Dieter Bitzer'
          position='Ansprechpartner'
          phone='07471 931191'
          mail='-'
          thumbnailUrl='/shared/person-icon.png'
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { HobbyTeamPage };
