import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FunctionComponent, ReactElement } from 'react';

interface ContactCardProps {
  name: string;
  position: string;
  phone?: string;
  mail?: string;
  thumbnailUrl?: string;
}

const StyledContactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 18rem;
  background-color: ${(props): any => props.theme.colors.pale};
  color: ${(props): any => props.theme.colors.secondary};
  box-shadow: ${(props): any => props.theme.boxShadows.raised};
`;

const CardImage = styled.img`
  width: 100%;
  height: 9rem;
  object-fit: cover;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);
`;

interface AvatarProps {
  src: string | undefined;
}

const Avatar = styled.div<AvatarProps>`
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  margin-top: -6rem;
  z-index: 0;
  border: .5rem solid ${(props): any => props.theme.colors.pale};
  background-image: url(${(props): any => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  image-rendering: -webkit-optimize-contrast; // Prevents images from getting blurry on some browsers
`;

const Title = styled.div`
  margin: .5rem 0;
  color: ${(props): any => props.theme.colors.hilight};
  text-transform: uppercase;
`;

const Position = styled.div`
  margin: .5rem 0;
`;

const ContactData = styled.ul`
  width: 90%;
  text-align: center;
  padding: .8em .8em;
  line-height: 1.4;
  list-style: none;
  margin: 0;

  > * {
    padding: .2em 0;
  }
`;

const ContactDataItem = styled.li`
  display: grid;
  column-gap: .8em;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: auto;
  grid-template-areas:
    'icon label'
    'icon data';

  background-color: ${(props): any => props.theme.colors.decent};
  margin-bottom: .5em;

  :last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  grid-area: icon;
  color: ${(props): any => props.theme.colors.hilight};
  align-self: center;
`;

const Label = styled.div`
  grid-area: label;
  color: ${(props): any => props.theme.colors.hilight};
  text-align: left;
  font-size: .9rem;
  align-self: flex-end;
`;

const Data = styled.div`
  grid-area: data;
  text-align: left;
  font-size: .9rem;
`;

const ContactCard: FunctionComponent<ContactCardProps> = ({ name, position, phone, mail, thumbnailUrl }): ReactElement => (
  <StyledContactCard>
    <CardImage src='/contacts/contact-background.jpg' alt='' className='background' />
    <Avatar src={ thumbnailUrl } />
    <Title>{name}</Title>
    <Position>{position}</Position>
    <ContactData>
      <ContactDataItem>
        <Icon>
          <FontAwesomeIcon icon={ faPhoneAlt } />
        </Icon>
        <Label>Telefon</Label>
        <Data>{phone}</Data>
      </ContactDataItem>
      <ContactDataItem>
        <Icon>
          <FontAwesomeIcon icon={ faEnvelope } />
        </Icon>
        <Label>E-Mail</Label>
        <Data>{mail}</Data>
      </ContactDataItem>
    </ContactData>
  </StyledContactCard>
);

export { ContactCard };
