import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavItemList } from './NavItemList';
import styled from 'styled-components';
import { StyledNavLink } from './StyledNavLink';
import { ReactElement, useState } from 'react';

const Nav = styled.nav`
  @media (min-width: 1125px) {
    display: none;
  }

  background-color: ${(props): any => props.theme.colors.secondary};
  padding: .5em 1em;
  border-bottom: 1px solid ${(props): any => props.theme.colors.hilight};
  position: sticky;
  top: 0;
  z-index: 1000;
  font-family: 'Ubuntu'
`;

const NavBar = styled.div`
  display: flex;
  wrap: no-wrap;
  justify-content: space-between;

  align-self: center;

  svg {
    color: ${(props): any => props.theme.colors.primary};
    align-self: center;
    
    &:hover {
      cursor: pointer;
    }
  }
`;

const NavbarMobile = function (): ReactElement {
  const [ isVisible, setVisible ] = useState(false);

  const handleNavigate = (): void => {
    setVisible(false);
  };

  return (
    <Nav>
      <NavBar>
        <StyledNavLink className='nav-left' to='/aktuell' onClick={ (): void => setVisible(false) }>SV Heselwangen 1906 e.V.</StyledNavLink>
        <FontAwesomeIcon icon={ faBars } onClick={ (): any => setVisible(!isVisible) } />
      </NavBar>
      { isVisible && <NavItemList onNavigate={ handleNavigate } /> }
    </Nav>
  );
};

export { NavbarMobile };
