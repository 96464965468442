/* eslint-disable prefer-named-capture-group */
import { GalleryImageContainer } from '../../domain/GalleryImageContainer';
import { GalleryMetadataCollection } from '../../domain/GalleryMetadataCollection';

const SvhSteinhofenContext = require.context('../../../public/galleries/matches/2023-2024/svh-steinhofen-03-09-23/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhGauselfingenContext = require.context('../../../public/galleries/matches/2023-2024/svh-gauselfingen-01-10-23/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhGruolContext = require.context('../../../public/galleries/matches/2023-2024/svh-gruol-15-10-23/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhSalmendingenContext = require.context('../../../public/galleries/matches/2023-2024/svh-salmendingen-29-10-23/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhHechingenContext = require.context('../../../public/galleries/matches/2023-2024/svh-hechingen-17-03-24/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhKillertalContext = require.context('../../../public/galleries/matches/2023-2024/svh-killertal-07-04-24/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhBollContext = require.context('../../../public/galleries/matches/2023-2024/svh-boll-26-05-24/standard/', false, /\.(png|jpe?g|svg)$/u);

const basePath = '/galleries/matches/2023-2024';

const getMatchGalleries202324 = (): GalleryMetadataCollection => {
  const collection = {
    galleries: [
      {
        title: 'SVH : TSV Boll (1:2)',
        date: '26.05.2024',
        imageCount: SvhBollContext.keys().length,
        images: SvhBollContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-boll-26-05-24/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-boll-26-05-24/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-boll-26-05-24',
        previewPath: `${basePath}/svh-boll-26-05-24/thumbnails/timg_0020.jpg`
      },
      {
        title: 'SVH : SGM SV Ringingen/FC Killertal 04 (3:0)',
        date: '07.04.2024',
        imageCount: SvhKillertalContext.keys().length,
        images: SvhKillertalContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-killertal-07-04-24/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-killertal-07-04-24/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-killertal-07-04-24',
        previewPath: `${basePath}/svh-killertal-07-04-24/thumbnails/timg_0013.jpg`
      },
      {
        title: 'SVH : FC Hechingen (0:0)',
        date: '17.03.2024',
        imageCount: SvhHechingenContext.keys().length,
        images: SvhHechingenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-hechingen-17-03-24/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-hechingen-17-03-24/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-hechingen-17-03-24',
        previewPath: `${basePath}/svh-hechingen-17-03-24/thumbnails/timg_0035.jpg`
      },
      {
        title: 'SVH : SGM FC Stetten-Salmendingen/TV Melchingen (1:2)',
        date: '29.10.2023',
        imageCount: SvhSalmendingenContext.keys().length,
        images: SvhSalmendingenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-salmendingen-29-10-23/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-salmendingen-29-10-23/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-salmendingen-29-10-23',
        previewPath: `${basePath}/svh-salmendingen-29-10-23/thumbnails/timg_0008.jpg`
      },
      {
        title: 'SVH : SGM SV Gruols/SV Erlaheim (0:3)',
        date: '15.10.2023',
        imageCount: SvhGruolContext.keys().length,
        images: SvhGruolContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-gruol-15-10-23/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-gruol-15-10-23/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-gruol-15-10-23',
        previewPath: `${basePath}/svh-gruol-15-10-23/thumbnails/timg_0008.jpg`
      },
      {
        title: 'SVH : SGM TSV Gauselfingen/TSV Hausen i.K. (5:1)',
        date: '01.10.2023',
        imageCount: SvhGauselfingenContext.keys().length,
        images: SvhGauselfingenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-gauselfingen-01-10-23/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-gauselfingen-01-10-23/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-gauselfingen-01-10-23',
        previewPath: `${basePath}/svh-gauselfingen-01-10-23/thumbnails/timg_0042.jpg`
      },
      {
        title: 'SVH : FC Steinhofen (2:2)',
        date: '03.09.2023',
        imageCount: SvhSteinhofenContext.keys().length,
        images: SvhSteinhofenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-steinhofen-03-09-23/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-steinhofen-03-09-23/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-steinhofen-03-09-23',
        previewPath: `${basePath}/svh-steinhofen-03-09-23/thumbnails/timg_0021.jpg`
      }
    ],
    totalImages: 0
  };

  collection.totalImages = collection.galleries.reduce((imageCount, gallery): number => imageCount + gallery.imageCount, 0);

  return collection;
};

export { getMatchGalleries202324 };

/* eslint-enable prefer-named-capture-group */
