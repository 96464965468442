import { Headline } from './Headline';
import { HeroSection } from './HeroSection';
import { Paragraph } from '../layout/Paragraph';
import { Section } from './Section';
import { TeamPreview } from './TeamPreview';
import { TeamsOverview } from './TeamsOverview';
import { Fragment, ReactElement } from 'react';

const teamsPath = '/teams';

const YouthTeamsOverview = (): ReactElement => (
  <Fragment>
    <HeroSection title='Saison 2024/2025'>
      <Paragraph> In der laufenden Saison haben wir 4 Jugend-Mannschaften von Bambini bis D-Jugend gemeldet.</Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Jugend-Mannschaften' />
      <TeamsOverview>
        {/* <TeamPreview title='A-Jugend' ageClass='U18/U19' division='Leistungsstaffel' to='/jugend/u19' imgSrc={ `${teamsPath}/u19/Mannschaftsfoto.jpeg` } /> */}
        {/* <TeamPreview title='B-Jugend' ageClass='U16/U17' division='Quali 2' to='/jugend/u17' imgSrc={ `${teamsPath}/u17/Mannschaftsfoto.jpg` } /> */}
        {/* <TeamPreview title='C-Jugend' ageClass='U14/U15' division='Qualigruppe 2' to='/jugend/u15' imgSrc={ `${teamsPath}/u15/Mannschaftsfoto.jpeg` } /> */}
        <TeamPreview title='D-Jugend' ageClass='U12/U13' division='Qualigruppe 3' to='/jugend/u13' imgSrc={ `${teamsPath}/u13/Mannschaftsfoto_2023.jpg` } />
        <TeamPreview title='E-Jugend' ageClass='U10/U11' division='Qualigruppe 5' to='/jugend/u11' imgSrc={ `${teamsPath}/u11/Mannschaftsfoto.jpg` } />
        <TeamPreview title='F-Jugend' ageClass='U8/U9' division='-' to='/jugend/u9' imgSrc={ `${teamsPath}/u9/Mannschaftsfoto.jpg` } />
        <TeamPreview title='Bambini' ageClass='U7' division='-' to='/jugend/u7' imgSrc={ `${teamsPath}/u7/Mannschaftsfoto.jpg` } />
      </TeamsOverview>
    </Section>
  </Fragment>
);

export { YouthTeamsOverview };
