/* eslint-disable prefer-named-capture-group */
import { GalleryImageContainer } from '../../domain/GalleryImageContainer';
import { GalleryMetadataCollection } from '../../domain/GalleryMetadataCollection';

const SvhKillertalContext = require.context('../../../public/galleries/matches/2024-2025/svh-killertal-25-08-24/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhRosenfeldContext = require.context('../../../public/galleries/matches/2024-2025/svh-rosenfeld-06-10-24/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhBurladingenContext = require.context('../../../public/galleries/matches/2024-2025/svh-burladingen-27-10-24/standard/', false, /\.(png|jpe?g|svg)$/u);

const basePath = '/galleries/matches/2024-2025';

const getMatchGalleries202425 = (): GalleryMetadataCollection => {
  const collection = {
    galleries: [
      {
        title: 'SVH : FC Burladingen (2:4)',
        date: '27.10.2024',
        imageCount: SvhBurladingenContext.keys().length,
        images: SvhBurladingenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-burladingen-27-10-24/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-burladingen-27-10-24/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-burladingen-27-10-24',
        previewPath: `${basePath}/svh-burladingen-27-10-24/thumbnails/timg_0013.jpg`
      },
      {
        title: 'SVH : SGM Rosenfeld/Isingen/Brittheim (2:3)',
        date: '06.10.2024',
        imageCount: SvhRosenfeldContext.keys().length,
        images: SvhRosenfeldContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-rosenfeld-06-10-24/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-rosenfeld-06-10-24/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-rosenfeld-06-10-24',
        previewPath: `${basePath}/svh-rosenfeld-06-10-24/thumbnails/timg_0023.jpg`
      },
      {
        title: 'SVH : SGM Ringingen/Killertal (0:3)',
        date: '25.08.2024',
        imageCount: SvhKillertalContext.keys().length,
        images: SvhKillertalContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-killertal-25-08-24/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-killertal-25-08-24/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-killertal-25-08-24',
        previewPath: `${basePath}/svh-killertal-25-08-24/thumbnails/timg_0010.jpg`
      }
    ],
    totalImages: 0
  };

  collection.totalImages = collection.galleries.reduce((imageCount, gallery): number => imageCount + gallery.imageCount, 0);

  return collection;
};

export { getMatchGalleries202425 };

/* eslint-enable prefer-named-capture-group */
