import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FunctionComponent, ReactElement } from 'react';

interface GalleryPreviewProps {
  title: string;
  date?: string;
  to?: string;
  previewPath?: string;
  className?: string;
}

const StyledGalleryPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  background-color: ${(props): any => props.theme.colors.primary};
  color: ${(props): any => props.theme.colors.secondary};
  box-shadow: ${(props): any => props.theme.boxShadows.raised};

  &.dark {
    background-color: ${(props): any => props.theme.colors.secondary};
    color: ${(props): any => props.theme.colors.primary};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  :hover {
    div.title {
      color: ${(props): any => props.theme.colors.hilight};
    }
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  object-fit: contain;
  // 101% to fix white line on right container edge
  -webkit-clip-path: polygon(0 0, 101% 0, 101% 98%, 85% 96%, 72% 99%, 58% 96%, 44% 98%, 28% 95%, 14% 99%, 0 97%);
  clip-path: polygon(0 0, 101% 0, 101% 98%, 85% 96%, 72% 99%, 58% 96%, 44% 98%, 28% 95%, 14% 99%, 0 97%);
`;

const Title = styled.div`
  font-size: .7rem;
  font-weight: 600;
  transition: color .2s ease-in-out;
`;

const Date = styled.div`
  font-size: .7rem;
  padding: .5rem;
  color: ${(props): any => props.theme.colors.decent};
`;

const GalleryPreview: FunctionComponent<GalleryPreviewProps> = ({ title, date, to, previewPath, className }): ReactElement => (
  <StyledGalleryPreview className={ className }>
    <Link to={ to ?? '' }>
      <Thumbnail src={ previewPath } alt={ title } />
      <Title className='title'>{ title }</Title>
      <Date>{ date }</Date>
    </Link>
  </StyledGalleryPreview>
);

export { GalleryPreview };
