import { LinkButton } from '../../../components/LinkButton';
import { ReactElement } from 'react';

// eslint-disable-next-line arrow-body-style
const NewsletterRegistrationButton = (): ReactElement => {
  return (
    <LinkButton
      label='Jetzt Anmelden'
      uri='https://9718981e.sibforms.com/serve/MUIEAMPNOiBrX1e-9bCg8EHVpOEa8Cot03PRMupy9Xnbsz2B-cDiBYZSIjMlZ2eTbXj7xtiDayWcdNAUlwfx-WWyflkMb19p1uwckZc02cQtoukGxMbBZqvLHqFMtarH5nd4Qx0tjl6vsG0LTm6DNOzkGjU5QTEcP6a0_t7UOn3sr4jX1O5CMnIqIb5CdhbXkXFmJ9Mir_r2-UnI'
      target='_blank'
    />
  );
};

export { NewsletterRegistrationButton };
