/* eslint-disable prefer-named-capture-group */
import { GalleryImageContainer } from '../../domain/GalleryImageContainer';
import { GalleryMetadataCollection } from '../../domain/GalleryMetadataCollection';

const SvhBollContext = require.context('../../../public/galleries/matches/2021-2022/svh-boll-15-05-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const FrommernSvhContext = require.context('../../../public/galleries/matches/2021-2022/frommern-svh-08-05-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhKillertalContext = require.context('../../../public/galleries/matches/2021-2022/svh-killertal-30-04-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const GrosselfingenSvhContext = require.context('../../../public/galleries/matches/2021-2022/grosselfingen-svh-18-04-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhSteinhofenContext = require.context('../../../public/galleries/matches/2021-2022/svh-steinhofen-10-04-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhRosenfeldContext = require.context('../../../public/galleries/matches/2021-2022/svh-rosenfeld-27-03-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const GeislingenSvhContext = require.context('../../../public/galleries/matches/2021-2022/geislingen-svh-07-11-21/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhStettenSalmendingenContext = require.context('../../../public/galleries/matches/2021-2022/svh-salmendingen-31-10-21/standard/', false, /\.(png|jpe?g|svg)$/u);
const BollSvhContext = require.context('../../../public/galleries/matches/2021-2022/boll-svh-24-10-21/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhFrommernContext = require.context('../../../public/galleries/matches/2021-2022/svh-frommern-17-10-21/standard/', false, /\.(png|jpe?g|svg)$/u);
const KillertalSvhContext = require.context('../../../public/galleries/matches/2021-2022/killertal-svh-10-10-21/standard/', false, /\.(png|jpe?g|svg)$/u);
const SteinhofenSvhContext = require.context('../../../public/galleries/matches/2021-2022/steinhofen-svh-26-09-21/standard/', false, /\.(png|jpe?g|svg)$/u);
const RosenfeldSvhContext = require.context('../../../public/galleries/matches/2021-2022/rosenfeld-svh-12-09-21/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhLeidringenContext = require.context('../../../public/galleries/matches/2021-2022/svh-leidringen-05-09-21/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhBinsdorfContext = require.context('../../../public/galleries/matches/2021-2022/svh-binsdorf-22-08-21/standard/', false, /\.(png|jpe?g|svg)$/u);
const TruchtelfingenSvhContext = require.context('../../../public/galleries/matches/2021-2022/truchtelfingen-svh-15-08-21/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhGruenWeissStettenContext = require.context('../../../public/galleries/matches/2021-2022/svh-stetten-19-08-21/standard/', false, /\.(png|jpe?g|svg)$/u);

const basePath = '/galleries/matches/2021-2022';

const getMatchGalleries202122 = (): GalleryMetadataCollection => {
  const collection = {
    galleries: [
      {
        title: 'SVH : TSV Boll (1:4)',
        date: '15.05.2022',
        imageCount: SvhBollContext.keys().length,
        images: SvhBollContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-boll-15-05-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-boll-15-05-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-boll-15-05-22',
        previewPath: `${basePath}/svh-boll-15-05-22/thumbnails/timg_0006.jpg`
      },
      {
        title: 'TSV Frommern : SVH (2:2)',
        date: '08.05.2022',
        imageCount: FrommernSvhContext.keys().length,
        images: FrommernSvhContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/frommern-svh-08-05-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/frommern-svh-08-05-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'frommern-svh-08-05-22',
        previewPath: `${basePath}/frommern-svh-08-05-22/thumbnails/timg_0006.jpg`
      },
      {
        title: 'SVH - FC Killertal 04 (1:0)',
        date: '30.04.2022',
        imageCount: SvhKillertalContext.keys().length,
        images: SvhKillertalContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-killertal-30-04-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-killertal-30-04-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-killertal-30-04-22',
        previewPath: `${basePath}/svh-killertal-30-04-22/thumbnails/timg_0020.jpg`
      },
      {
        title: 'FC Grosselfingen - SVH (2:0)',
        date: '18.04.2022',
        imageCount: GrosselfingenSvhContext.keys().length,
        images: GrosselfingenSvhContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/grosselfingen-svh-18-04-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/grosselfingen-svh-18-04-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'grosselfingen-svh-18-04-22',
        previewPath: `${basePath}/grosselfingen-svh-18-04-22/thumbnails/timg_0012.jpg`
      },
      {
        title: 'SVH - FC Steinhofen (3:2)',
        date: '10.04.2022',
        imageCount: SvhSteinhofenContext.keys().length,
        images: SvhSteinhofenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-steinhofen-10-04-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-steinhofen-10-04-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-steinhofen-10-04-22',
        previewPath: `${basePath}/svh-steinhofen-10-04-22/thumbnails/timg_0016.jpg`
      },
      {
        title: 'SVH - SV Rosenfeld (2:2)',
        date: '27.03.2022',
        imageCount: SvhRosenfeldContext.keys().length,
        images: SvhRosenfeldContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-rosenfeld-27-03-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-rosenfeld-27-03-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-rosenfeld-27-03-22',
        previewPath: `${basePath}/svh-rosenfeld-27-03-22/thumbnails/timg_0013.jpg`
      },
      {
        title: 'TSV Geislingen : SVH (2:0)',
        date: '07.11.2021',
        imageCount: GeislingenSvhContext.keys().length,
        images: GeislingenSvhContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/geislingen-svh-07-11-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/geislingen-svh-07-11-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'geislingen-svh-07-11-21',
        previewPath: `${basePath}/geislingen-svh-07-11-21/thumbnails/timg_0012.jpg`
      },
      {
        title: 'SVH : FC Stetten/Salmendingen (0:5)',
        date: '31.10.2021',
        imageCount: SvhStettenSalmendingenContext.keys().length,
        images: SvhStettenSalmendingenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-salmendingen-31-10-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-salmendingen-31-10-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-salmendingen-31-10-21',
        previewPath: `${basePath}/svh-salmendingen-31-10-21/thumbnails/timg_0020.jpg`
      },
      {
        title: 'TSV Boll : SVH (2:0)',
        date: '24.10.2021',
        imageCount: BollSvhContext.keys().length,
        images: BollSvhContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/boll-svh-24-10-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/boll-svh-24-10-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'boll-svh-24-10-21',
        previewPath: `${basePath}/boll-svh-24-10-21/thumbnails/timg_0001.jpg`
      },
      {
        title: 'SVH : TSV Frommern II (1:0)',
        date: '17.10.2021',
        imageCount: SvhFrommernContext.keys().length,
        images: SvhFrommernContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-frommern-17-10-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-frommern-17-10-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-frommern-17-10-21',
        previewPath: `${basePath}/svh-frommern-17-10-21/thumbnails/timg_0019.jpg`
      },
      {
        title: 'FC Killertal : SVH (2:0)',
        date: '10.10.2021',
        imageCount: KillertalSvhContext.keys().length,
        images: KillertalSvhContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/killertal-svh-10-10-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/killertal-svh-10-10-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'killertal-svh-10-10-21',
        previewPath: `${basePath}/killertal-svh-10-10-21/thumbnails/timg_0023.jpg`
      },
      {
        title: 'FC Steinhofen : SVH (3:3)',
        date: '26.09.2021',
        imageCount: SteinhofenSvhContext.keys().length,
        images: SteinhofenSvhContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/steinhofen-svh-26-09-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/steinhofen-svh-26-09-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'steinhofen-svh-26-09-21',
        previewPath: `${basePath}/steinhofen-svh-26-09-21/thumbnails/timg_0001.jpg`
      },
      {
        title: 'SV Rosenfeld : SVH (2:0)',
        date: '12.09.2021',
        imageCount: RosenfeldSvhContext.keys().length,
        images: RosenfeldSvhContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/rosenfeld-svh-12-09-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/rosenfeld-svh-12-09-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'rosenfeld-svh-12-09-21',
        previewPath: `${basePath}/rosenfeld-svh-12-09-21/thumbnails/timg_0001.jpg`
      },
      {
        title: 'SVH : SpVgg Leidringen (2:3)',
        date: '05.09.2021',
        imageCount: SvhLeidringenContext.keys().length,
        images: SvhLeidringenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-leidringen-05-09-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-leidringen-05-09-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-leidringen-05-09-21',
        previewPath: `${basePath}/svh-leidringen-05-09-21/thumbnails/timg_0001.jpg`
      },
      {
        title: 'SVH : SpVgg Binsdorf (3:1)',
        date: '22.08.2021',
        imageCount: SvhBinsdorfContext.keys().length,
        images: SvhBinsdorfContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-binsdorf-22-08-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-binsdorf-22-08-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-binsdorf-22-08-21',
        previewPath: `${basePath}/svh-binsdorf-22-08-21/thumbnails/timg_0006.jpg`
      },
      {
        title: 'SVH - SV Grün-Weiss Stetten (0:4)',
        date: '19.08.2021',
        imageCount: SvhGruenWeissStettenContext.keys().length,
        images: SvhGruenWeissStettenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-stetten-19-08-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-stetten-19-08-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-stetten-19-08-21',
        previewPath: `${basePath}/svh-stetten-19-08-21/thumbnails/timg_0006.jpg`
      },
      {
        title: 'SpVgg Truchtelfingen - SVH (1:2)',
        date: '15.08.2021',
        imageCount: TruchtelfingenSvhContext.keys().length,
        images: TruchtelfingenSvhContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/truchtelfingen-svh-15-08-21/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/truchtelfingen-svh-15-08-21/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'truchtelfingen-svh-15-08-21',
        previewPath: `${basePath}/truchtelfingen-svh-15-08-21/thumbnails/timg_0006.jpg`
      }
    ],
    totalImages: 0
  };

  collection.totalImages = collection.galleries.reduce((imageCount, gallery): number => imageCount + gallery.imageCount, 0);

  return collection;
};

export { getMatchGalleries202122 };

/* eslint-enable prefer-named-capture-group */
