import styled from 'styled-components';
import { FunctionComponent, ReactElement, useCallback, useEffect, useLayoutEffect, useState } from 'react';

interface FussbaldeWidgetProps {
  elementId: string;
  websiteKey: string;
}

interface ContainerProps {
  scale: number;
}

const Container = styled.div<ContainerProps>`
  transform: scale(${(props): any => props.scale});

  ${(props): any => props.scale < 1 && `
    // transform-origin: top left;
  `}
`;

const FussballdeWidget: FunctionComponent<FussbaldeWidgetProps> = ({ elementId, websiteKey }): ReactElement => {
  const [ scale, setScale ] = useState(1);

  const rescaleWidget = useCallback((): void => {
    const rootElement = document.getElementById('root');
    const widget = document.getElementById(elementId);

    if (rootElement && widget) {
      const currentScale = rootElement.clientWidth / 548;

      if (currentScale < 1) {
        setScale(currentScale);
      } else {
        setScale(1);
      }
    }
  }, [ elementId ]);

  useEffect((): void => {
    const widgetScript = document.createElement('script');

    widgetScript.async = true;
    widgetScript.innerHTML = `new fussballdeWidgetAPI().showWidget('${elementId}', '${websiteKey}')`;

    document.body.appendChild(widgetScript);
  }, [ elementId, websiteKey ]);

  useLayoutEffect((): void => {
    rescaleWidget();
  }, [ rescaleWidget ]);

  window.addEventListener('resize', rescaleWidget);

  return (
    <Container id={ elementId } scale={ scale } />
  );
};

export { FussballdeWidget };
