import { Headline } from './Headline';
import { HeroSection } from './HeroSection';
import { Paragraph } from '../layout/Paragraph';
import { Section } from './Section';
import { TeamPreview } from './TeamPreview';
import { TeamsOverview } from './TeamsOverview';
import { Fragment, ReactElement } from 'react';

const teamsPath = '/teams';

const SeniorTeamsOverview = (): ReactElement => (
  <Fragment>
    <HeroSection title='Saison 2023/2024'>
      <Paragraph> In der laufenden Saison haben wir zwei aktive Mannschaften gemeldet, die uns in der Kreisliga A und Kreisliga B vertreten. </Paragraph>
      <Paragraph> Außerdem haben wir noch eine AH-Mannschaft für alle Veteranen und Freizeit-Kicker. </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Mannschaften' />
      <TeamsOverview>
        <TeamPreview title='1. Mannschaft' ageClass='Herren' division='Kreisliga A' to='/mannschaften/1_mannschaft' imgSrc={ `${teamsPath}/senior/Mannschaftsfoto.jpg` } />
        <TeamPreview title='2. Mannschaft' ageClass='Herren' division='Kreisliga B' to='/mannschaften/2_mannschaft' imgSrc={ `${teamsPath}/u23/Mannschaftsfoto.jpg` } />
        <TeamPreview title='AH Mannschaft' ageClass='Ü32' division='Hobbymannschaft' to='/mannschaften/ah_mannschaft' imgSrc={ `${teamsPath}/hobby/Mannschaftsfoto.jpg` } />
      </TeamsOverview>
    </Section>
  </Fragment>
);

export { SeniorTeamsOverview };
