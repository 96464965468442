import { GalleriesPage } from './GalleriesPage';
import { getMatchGalleries202122 } from '../getMatchGalleries202122';
import { ReactElement } from 'react';

const Matches202122Page = (): ReactElement => {
  const galleryCollection = getMatchGalleries202122();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Saison 2021 / 2022' />
  );
};

export { Matches202122Page };
