import FullCalendar from '@fullcalendar/react';
import TimeGridPlugin from '@fullcalendar/timegrid';
import { TrainingEvent } from '../../../domain/TrainingEvent';
import { useTheme } from 'styled-components';
import { FunctionComponent, ReactElement } from 'react';

interface TrainingScheduleProps {
  events: TrainingEvent[];
}

const TrainingSchedule: FunctionComponent<TrainingScheduleProps> = ({ events }): ReactElement => {
  const theme = useTheme();

  return (
    <FullCalendar
      plugins={ [ TimeGridPlugin ] }
      views={
        { timeGridWeek: {
          dayHeaderFormat: { weekday: 'long' }
        }}
      }
      height='auto'
      editable={ false }
      headerToolbar={{
        left: '',
        center: '',
        right: ''
      }}
      allDaySlot={ false }
      slotMinTime='09:00:00'
      slotMaxTime='21:00:00'
      locale='de'
      hiddenDays={ [ 0 ] }
      events={ events }
      slotEventOverlap={ false }
      eventBackgroundColor={ theme.colors.hilight }
      eventBorderColor={ theme.colors.secondary }
      eventTextColor={ theme.colors.primary }
    />
  );
};

export { TrainingSchedule };
