import styled from 'styled-components';
import { FunctionComponent, ReactElement } from 'react';

const StyledHeadline = styled.div`
  margin-bottom: 1.5em;
  font-size: 2rem;
  letter-spacing: 0.08em;

  &:not(:first-child) {
    margin-top: 1.3em;
  }
`;

interface HeadlineProps {
  title: string;
}

const Headline: FunctionComponent<HeadlineProps> = ({ title }): ReactElement => (
  <StyledHeadline>
    { title }
  </StyledHeadline>
);

export { Headline };
