import styled from 'styled-components';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

const StyledGallerySectionOverview = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`;

interface GallerySectionOverviewProps {
  children: ReactNode;
}

const GallerySectionOverview: FunctionComponent<GallerySectionOverviewProps> = ({ children }): ReactElement => (
  <StyledGallerySectionOverview>
    { children }
  </StyledGallerySectionOverview>
);

export { GallerySectionOverview };
