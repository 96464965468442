/* eslint-disable prefer-named-capture-group */
import { GalleryImageContainer } from '../../domain/GalleryImageContainer';
import { GalleryMetadataCollection } from '../../domain/GalleryMetadataCollection';

const GartenschauContext = require.context('../../../public/galleries/trips/2024/gartenschau-wangen/standard/', false, /\.(png|jpe?g|svg)$/u);
const AhWanderungContext = require.context('../../../public/galleries/trips/2024/ah-wanderung/standard/', false, /\.(png|jpe?g|svg)$/u);

const basePath = '/galleries/trips/2024';

const getTripGalleries2024 = (): GalleryMetadataCollection => {
  const collection = {
    galleries: [
      {
        title: 'AH-Wanderung 2024',
        date: '20.09.2024 - 22.09.2024',
        imageCount: AhWanderungContext.keys().length,
        images: AhWanderungContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/ah-wanderung/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/ah-wanderung/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'ah-wanderung',
        previewPath: `${basePath}/ah-wanderung/thumbnails/timg_0060.jpg`
      },
      {
        title: 'Gartenschau Wangen 2024',
        date: '07.09.2024',
        imageCount: GartenschauContext.keys().length,
        images: GartenschauContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/gartenschau-wangen/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/gartenschau-wangen/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'gartenschau-wangen',
        previewPath: `${basePath}/gartenschau-wangen/thumbnails/timg_0025.jpg`
      }
    ],
    totalImages: 0
  };

  collection.totalImages = collection.galleries.reduce((imageCount, gallery): number => imageCount + gallery.imageCount, 0);

  return collection;
};

export { getTripGalleries2024 };
/* eslint-enable prefer-named-capture-group */
