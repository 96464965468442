import { Division } from './Division';
import { DivisionItem } from './DivisionItem';
import { getAgendaEvents } from '../app/club/agendaService';
import { Headline } from './Headline';
import { HeroSection } from './HeroSection';
import { Paragraph } from '../layout/Paragraph';
import { Polaroid } from './Polaroid';
import { Section } from './Section';
import styled from 'styled-components';
import { YearsAgenda } from './YearsAgenda';
import { Fragment, ReactElement } from 'react';

const ImageGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 1.5rem;
  width: 80%;
  margin: auto;
`;

const ImageContainer = styled.div`
  width: 80%;
  margin: auto;
  margin-bottom: 1.5rem;

  img {
    max-width: 100%;
  }
`;

const ClubPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='Verein'>
      <Paragraph> Hier findet ihr allgemeine Informationen zum Verein und seinen Mitgliedern. </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Jahresprogramm' />
      <YearsAgenda events={ getAgendaEvents() } />
    </Section>
    <Section className='dark'>
      <Headline title='Historie' />
      <Paragraph>
        Im Jahr 2006 feierte der SV Heselwangen 1906 e.V. sein 100 Jähriges bestehen.
        Das Programm der Festtage, die vom 07. bis 10. Juli 2006 andauerten, sowie viele interessante Fakten zum
        Verein, sowohl aus dem Jahr 2006 wie auch aus den vergangenen 100 Jahren wurden in einer Festschrift zusammengetragen.
      </Paragraph>
      <ImageGroup>
        <ImageContainer>
          <img src='/history/100-jahre-svh-preview-1.jpeg' alt='100 Jahre SVH Vorschau' />
        </ImageContainer>
        <ImageContainer>
          <img src='/history/100-jahre-svh-preview-2.jpeg' alt='100 Jahre SVH Vorschau' />
        </ImageContainer>
        <ImageContainer>
          <img src='/history/100-jahre-svh-preview-3.jpeg' alt='100 Jahre SVH Vorschau' />
        </ImageContainer>
      </ImageGroup>
      <Paragraph>
        Diese steht euch hier als <a href='/history/100-Jahre-SVH.pdf' target='_blank' rel='noreferrer'>PDF-Download</a> zur Verfügung.
      </Paragraph>
    </Section>
    <Section>
      <Headline title='Schiedsrichter' />
      <Division title=''>
        <DivisionItem>
          <Polaroid src='/shared/person-icon.png' title='Niklas Bühler' />
        </DivisionItem>
        <DivisionItem>
          <Polaroid src='/referees/Hartmut-Landbeck.jpg' title='Hartmut Landbeck' />
        </DivisionItem>
        <DivisionItem>
          <Polaroid src='/referees/Dennis_Mayer.jpg' title='Dennis Meyer' />
        </DivisionItem>
        <DivisionItem>
          <Polaroid src='/referees/David_Nisch.jpg' title='David Nisch' />
        </DivisionItem>
        <DivisionItem>
          <Polaroid src='/referees/Philip_Prinz.jpg' title='Philipp Prinz' />
        </DivisionItem>1
        <DivisionItem>
          <Polaroid src='/shared/person-icon.png' title='Waldemar Raczek' />
        </DivisionItem>
        <DivisionItem>
          <Polaroid src='/referees/Tim_Rist.jpg' title='Tim Rist' />
        </DivisionItem>
        <DivisionItem>
          <Polaroid src='/shared/person-icon.png' title='Robin Seeigs' />
        </DivisionItem>
      </Division>
    </Section>
  </Fragment>
);

export { ClubPage };
