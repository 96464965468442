/* eslint-disable prefer-named-capture-group */
import { GalleryImageContainer } from '../../domain/GalleryImageContainer';
import { GalleryMetadataCollection } from '../../domain/GalleryMetadataCollection';

const ElfmeterTurnierContext = require.context('../../../public/galleries/events/2019/elfmeter-turnier-2019/standard/', false, /\.(png|jpe?g|svg)$/u);

const basePath = '/galleries/events/2019';

const getEventGalleries2019 = (): GalleryMetadataCollection => {
  const collection = {
    galleries: [
      {
        title: '11-Meter-Turnier 2019',
        date: '30.05.2019',
        imageCount: ElfmeterTurnierContext.keys().length,
        images: ElfmeterTurnierContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/elfmeter-turnier-2019/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/elfmeter-turnier-2019/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'elfmeter-turnier-2019',
        previewPath: `${basePath}/elfmeter-turnier-2019/thumbnails/timg_0012.jpg`
      }
    ],
    totalImages: 0
  };

  collection.totalImages = collection.galleries.reduce((imageCount, gallery): number => imageCount + gallery.imageCount, 0);

  return collection;
};

export { getEventGalleries2019 };
/* eslint-enable prefer-named-capture-group */
