import { Headline } from '../../components/Headline';
import { HeroSection } from '../../components/HeroSection';
import { Paragraph } from '../../layout/Paragraph';
import { Section } from '../../components/Section';
import styled from 'styled-components';
import { Fragment, ReactElement } from 'react';

const Impressum = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: fit-content;
  margin: auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 1.5rem;
  flex-grow: 1;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  width: 100%;
  align-items: baseline;
  text-align: left;

  h4 {
    margin: 0;
  }

  div, h4 {
    line-height: 1.4;
  }
`;

const ImpressumPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='Impressum'>
      <Paragraph>
        In unserem Impressum findet ihr die gesetzlich vorgeschriebenen Angaben zu den für den Inhalt auf dieser Seite verantwortlichen Personen.
      </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Vereinsimpressum nach § 5 des Telemediengesetzes' />
      <Impressum>
        <Column>
          <Row>
            <h4>Verein</h4>
            <div>
              SV Heselwangen 1906 e.V. <br />
              Bürgermeister-Jetter-Straße 74 <br />
              72336 Balingen <br />
              <br />
              kontakt@sv-heselwangen.de
            </div>
          </Row>
          <Row>
            <h4>USt-ID</h4>
            <div>
              DE456789
            </div>
          </Row>
          <Row>
            <h4>Registergericht</h4>
            <div>
              Amtsgericht Stuttgart
            </div>
          </Row>
          <Row>
            <h4>Registernummer</h4>
            <div>
              VR 90
            </div>
          </Row>
          <Row>
            <h4>V.i.S.d § 18 Abs. 2 MStV</h4>
            <div>
              Kevin Schuler <br />
              Zollernstr. 27 <br />
              72336 Balingen
            </div>
          </Row>
        </Column>
        <Column>
          <Row>
            <h4>1. Vorstand</h4>
            <div>
              Harmut Landbeck <br />
              Carl-Zuckmayer-Weg 4 <br />
              72336 Balingen <br />
              <br />
              0177 2146491
            </div>
          </Row>
          <Row>
            <h4>2. Vorstand</h4>
            <div>
              Joe Jetter <br />
              Geißbühlstr. 23 <br />
              72336 Balingen <br />
              <br />
              07433 9032815
            </div>
          </Row>
        </Column>
      </Impressum>
    </Section>
  </Fragment>
);

export { ImpressumPage };
