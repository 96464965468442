import { GalleriesPage } from './GalleriesPage';
import { getEventGalleries2017 } from '../getEventGalleries2017';
import { ReactElement } from 'react';

const Events2017Page = (): ReactElement => {
  const galleryCollection = getEventGalleries2017();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Events 2017' />
  );
};

export { Events2017Page };
