import { GalleriesPage } from './GalleriesPage';
import { getTripGalleries2024 } from '../getTripGalleries2024';
import { ReactElement } from 'react';

const Trips2024Page = (): ReactElement => {
  const galleryCollection = getTripGalleries2024();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Ausflüge 2024' />
  );
};

export { Trips2024Page };
