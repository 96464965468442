import styled from 'styled-components';
import { FunctionComponent, ReactChild, ReactElement, useEffect, useRef, useState } from 'react';

const SlideshowContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;
`;

interface SlideshowSliderProps {
  index: number;
}

const SlideshowSlider = styled.div<SlideshowSliderProps>`
  white-space: nowrap;
  transition: ease 1000ms;

  transform: translate3d(-${(props): any => props.index * 100}%, 0, 0);
`;

const Slide = styled.div`
  display: inline-block;

  height: fit-content;
  width: 100%;
`;

const SlideshowDots = styled.div`
  text-align: center;
`;

interface SlideshowDotProps {
  active: boolean;
}

const SlideshowDot = styled.button<SlideshowDotProps>`
  display: inline-block;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  cursor: pointer;

  margin: 1rem .5rem 0px;

  background-color: ${(props): any => props.theme.colors.pale};

  ${(props): any => props.active && `
    background-color: ${props.theme.colors.hilight};
  `}
`;

interface SlideshowProps {
  children: ReactChild[];
  delay: number;
}

const Slideshow: FunctionComponent<SlideshowProps> = ({ children, delay }): ReactElement => {
  const [ index, setIndex ] = useState(0);

  const timeoutRef = useRef(0);

  const resetTimeout = (): void => window.clearTimeout(timeoutRef.current);

  useEffect((): void => {
    resetTimeout();
    timeoutRef.current = window.setTimeout((): void => {
      setIndex((prevIndex): number =>
        prevIndex === children.length - 1 ? 0 : prevIndex + 1);
    }, delay);
  }, [ index, children, delay ]);

  return (
    <SlideshowContainer>
      <SlideshowSlider index={ index }>
        {children.map((child, mapIndex): ReactElement => (
          // eslint-disable-next-line react/no-array-index-key
          <Slide key={ mapIndex }>
            { child }
          </Slide>
        ))}
      </SlideshowSlider>
      <SlideshowDots>
        {children.map((child, idx): ReactElement => (
          // eslint-disable-next-line react/jsx-key
          <SlideshowDot
            active={ index === idx }
            onClick={ (): void => setIndex(idx) }
          />
        ))}
      </SlideshowDots>
    </SlideshowContainer>
  );
};

export { Slideshow };
