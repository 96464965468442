/* eslint-disable prefer-named-capture-group */
import { GalleryImageContainer } from '../../domain/GalleryImageContainer';
import { GalleryMetadataCollection } from '../../domain/GalleryMetadataCollection';

const SvhHeubergContext = require.context('../../../public/galleries/matches/2022-2023/svh-heuberg-01-09-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhRingingenContext = require.context('../../../public/galleries/matches/2022-2023/svh-ringingen-04-09-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhRosenfeldContext = require.context('../../../public/galleries/matches/2022-2023/svh-rosenfeld-25-09-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhBinsdorfContext = require.context('../../../public/galleries/matches/2022-2023/svh-binsdorf-09-10-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhOwingenContext = require.context('../../../public/galleries/matches/2022-2023/svh-owingen-23-10-22/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhSalmendingenContext = require.context('../../../public/galleries/matches/2022-2023/svh-salmendingen-02-04-23/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhBittelbronnContext = require.context('../../../public/galleries/matches/2022-2023/svh-bittelbronn-23-04-23/standard/', false, /\.(png|jpe?g|svg)$/u);
const SickingenSvhContext = require.context('../../../public/galleries/matches/2022-2023/sickingen-svh-04-06-23/standard/', false, /\.(png|jpe?g|svg)$/u);
const SvhSteinhofenContext = require.context('../../../public/galleries/matches/2022-2023/svh-steinhofen-10-06-23/standard/', false, /\.(png|jpe?g|svg)$/u);

const basePath = '/galleries/matches/2022-2023';

const getMatchGalleries202223 = (): GalleryMetadataCollection => {
  const collection = {
    galleries: [
      {
        title: 'SVH : FC Steinhofen (5:0)',
        date: '10.06.2023',
        imageCount: SvhSteinhofenContext.keys().length,
        images: SvhSteinhofenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-steinhofen-10-06-23/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-steinhofen-10-06-23/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-steinhofen-10-06-23',
        previewPath: `${basePath}/svh-steinhofen-10-06-23/thumbnails/timg_0033.jpg`
      },
      {
        title: 'Spfr. Sickingen : SVH (2:1)',
        date: '04.06.2023',
        imageCount: SickingenSvhContext.keys().length,
        images: SickingenSvhContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/sickingen-svh-04-06-23/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/sickingen-svh-04-06-23/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'sickingen-svh-04-06-23',
        previewPath: `${basePath}/sickingen-svh-04-06-23/thumbnails/timg_0027.jpg`
      },
      {
        title: 'SVH : SG Weildorf/Bittelbronn (0:5)',
        date: '23.04.2023',
        imageCount: SvhBittelbronnContext.keys().length,
        images: SvhBittelbronnContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-bittelbronn-23-04-23/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-bittelbronn-23-04-23/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-bittelbronn-23-04-23',
        previewPath: `${basePath}/svh-bittelbronn-23-04-23/thumbnails/timg_0012.jpg`
      },
      {
        title: 'SVH : FC Stetten/Salmendingen (6:1)',
        date: '02.04.2023',
        imageCount: SvhSalmendingenContext.keys().length,
        images: SvhSalmendingenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-salmendingen-02-04-23/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-salmendingen-02-04-23/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-salmendingen-02-04-23',
        previewPath: `${basePath}/svh-salmendingen-02-04-23/thumbnails/timg_0015.jpg`
      },
      {
        title: 'SVH : SGM SV Hart / SV Owingen (1:1)',
        date: '23.10.2022',
        imageCount: SvhOwingenContext.keys().length,
        images: SvhOwingenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-owingen-23-10-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-owingen-23-10-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-owingen-23-10-22',
        previewPath: `${basePath}/svh-owingen-23-10-22/thumbnails/timg_0030.jpg`
      },
      {
        title: 'SVH : SpVgg Binsdorf (1:2)',
        date: '09.10.2022',
        imageCount: SvhBinsdorfContext.keys().length,
        images: SvhBinsdorfContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-binsdorf-09-10-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-binsdorf-09-10-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-binsdorf-09-10-22',
        previewPath: `${basePath}/svh-binsdorf-09-10-22/thumbnails/timg_0033.jpg`
      },
      {
        title: 'SVH : SGM SV Rosenfeld / TSV Brittheim (1:1)',
        date: '25.09.2022',
        imageCount: SvhRosenfeldContext.keys().length,
        images: SvhRosenfeldContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-rosenfeld-25-09-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-rosenfeld-25-09-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-rosenfeld-25-09-22',
        previewPath: `${basePath}/svh-rosenfeld-25-09-22/thumbnails/timg_0005.jpg`
      },
      {
        title: 'SVH : SV Ringingen (5:2)',
        date: '04.09.2022',
        imageCount: SvhRingingenContext.keys().length,
        images: SvhRingingenContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-ringingen-04-09-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-ringingen-04-09-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-ringingen-04-09-22',
        previewPath: `${basePath}/svh-ringingen-04-09-22/thumbnails/timg_0029.jpg`
      },
      {
        title: 'SVH : SGM Heuberg (2:1)',
        date: '01.09.2022',
        imageCount: SvhHeubergContext.keys().length,
        images: SvhHeubergContext.keys().map((item: string): GalleryImageContainer => ({
          imagePath: `${basePath}/svh-heuberg-01-09-22/standard/${item.replace('./', '')}`,
          thumbnailPath: `${basePath}/svh-heuberg-01-09-22/thumbnails/${item.replace('./', 't')}`
        })),
        routePath: 'svh-heuberg-01-09-22',
        previewPath: `${basePath}/svh-heuberg-01-09-22/thumbnails/timg_0010.jpg`
      }
    ],
    totalImages: 0
  };

  collection.totalImages = collection.galleries.reduce((imageCount, gallery): number => imageCount + gallery.imageCount, 0);

  return collection;
};

export { getMatchGalleries202223 };

/* eslint-enable prefer-named-capture-group */
