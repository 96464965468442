import { NavItemList } from './NavItemList';
import { noop } from 'lodash';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { StyledNavLink } from './StyledNavLink';

const Nav = styled.nav`
  @media (max-width: 1125px) {
    display: none;
  }

  display: flex;
  justify-content: flex-end;
  background-color: ${(props): any => props.theme.colors.secondary};
  padding: .5em 1em;
  border-bottom: 1px solid ${(props): any => props.theme.colors.hilight};
  position: sticky;
  top: 0;
  z-index: 1000;
  font-family: 'Ubuntu'
`;

const NavbarDesktop = function (): ReactElement {
  return (
    <Nav>
      <StyledNavLink className='nav-left' to='/aktuell'>SV Heselwangen 1906 e.V.</StyledNavLink>
      <NavItemList className='desktop' onNavigate={ (): void => noop() } />
    </Nav>
  );
};

export { NavbarDesktop };
