import styled from 'styled-components';
import { StyledNavLink } from './StyledNavLink';
import { FunctionComponent, ReactElement } from 'react';

const NavUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  &.desktop {
    display: flex;
  }
`;

const NavLi = styled.li`
  align-self: center;
  text-transform: uppercase;
  font-size: .7rem;
`;

interface NavItemListProps {
  className?: string;
  onNavigate: () => void;
}

const NavItemList: FunctionComponent<NavItemListProps> = function ({ className, onNavigate }): ReactElement {
  const handleNavigate = (): void => {
    onNavigate();
  };

  return (
    <NavUl className={ className }>
      <NavLi>
        <StyledNavLink activeClassName='is-active' to='/mannschaften' onClick={ handleNavigate }>Mannschaften</StyledNavLink>
      </NavLi>
      <NavLi>
        <StyledNavLink activeClassName='is-active' to='/jugend' onClick={ handleNavigate }>Jugend</StyledNavLink>
      </NavLi>
      <NavLi>
        <StyledNavLink activeClassName='is-active' to='/gymnastik' onClick={ handleNavigate }>Gymnastik</StyledNavLink>
      </NavLi>
      <NavLi>
        <StyledNavLink activeClassName='is-active' to='/galerie' onClick={ handleNavigate }>Galerie</StyledNavLink>
      </NavLi>
      <NavLi>
        <StyledNavLink activeClassName='is-active' to='/verein' onClick={ handleNavigate }>Verein</StyledNavLink>
      </NavLi>
      <NavLi>
        <StyledNavLink activeClassName='is-active' to='/foerderverein' onClick={ handleNavigate }>Förderverein</StyledNavLink>
      </NavLi>
      <NavLi>
        <StyledNavLink activeClassName='is-active' to='/kontakte' onClick={ handleNavigate }>Kontakte</StyledNavLink>
      </NavLi>
    </NavUl>
  );
};

export { NavItemList };
