import { ContactCard } from '../../components/ContactCard';
import { ContactGroup } from '../../components/ContactGroup';
import { Division } from '../../components/Division';
import { DivisionItem } from '../../components/DivisionItem';
import { Headline } from '../../components/Headline';
import { HeroSection } from '../../components/HeroSection';
import { Paragraph } from '../../layout/Paragraph';
import { Polaroid } from '../../components/Polaroid';
import { Section } from '../../components/Section';
import { TeamPhoto } from '../../components/TeamPhoto';
import { Fragment, ReactElement } from 'react';

const GymnastikPage = (): ReactElement => (
  <Fragment>
    <HeroSection title='Gymnastik'>
      <Paragraph> Unsere Gymnastik-Abteilung besteht aus der Fit-Gymnastik der Damen, der Herren-Gymnastik sowie dem Eltern-Kind-Turnen. </Paragraph>
    </HeroSection>
    <Section>
      <Headline title='Fit-Gymnastik' />
      <TeamPhoto src='/teams/fit-gymnastik/fit-gymnastik-gruppenfoto.jpg' title='Fit-Gymnastik-Gruppe' />
    </Section>
    <Section className='dark'>
      <Headline title='Herren-Gymnastik' />
      <Paragraph>
        Das Gruppenfoto der Herren-Gymnastik folgt ...
      </Paragraph>
    </Section>
    <Section>
      <Headline title='Eltern-Kind-Turnen' />
      <TeamPhoto src='/teams/ekt/eltern-kind-turnen.jpeg' title='EKT Gruppe' />
      <Division title=''>
        <DivisionItem>
          <Polaroid src='/teams/ekt/impressionen-1.jpg' title='Impressionen EKT' />
        </DivisionItem>
        <DivisionItem>
          <Polaroid src='/teams/ekt/impressionen-2.jpg' title='Impressionen EKT' />
        </DivisionItem>
        <DivisionItem>
          <Polaroid src='/teams/ekt/impressionen-3.jpg' title='Impressionen EKT' />
        </DivisionItem>
      </Division>
    </Section>
    <Section className='dark'>
      <Headline title='Trainingszeiten' />
      <Paragraph>Fit-Gymnastik: Dienstags 19:30 Uhr</Paragraph>
      <Paragraph>Herren-Gymnastik: Mittwochs 19:30 Uhr</Paragraph>
      <Paragraph>Eltern-Kind-Turnen: Dienstags 09:15 Uhr im Vereinsheim Heselwangen und Mittwochs 16:30 Uhr in der Schulsporthalle Engstlatt</Paragraph>
    </Section>
    <Section>
      <Headline title='Trainer und Betreuer' />
      <ContactGroup>
        <ContactCard
          name='Beate Bisinger'
          position='Übungsleiterin Fit-Gymnastik'
          phone='0173 7771121'
          mail='-'
          thumbnailUrl='/shared/person-icon.png'
        />
        <ContactCard
          name='Heinz Günther'
          position='Übungsleiter Herren-Gymnastik'
          phone='07433 15216'
          mail='-'
          thumbnailUrl='/shared/person-icon.png'
        />
        <ContactCard
          name='Elisa Streich'
          position='Übungsleiterin EKT'
          phone='-'
          mail='-'
          thumbnailUrl='/shared/person-icon.png'
        />
        <ContactCard
          name='Manuela Lassel'
          position='Übungsleiterin EKT'
          phone='-'
          mail='-'
          thumbnailUrl='/shared/person-icon.png'
        />
      </ContactGroup>
    </Section>
  </Fragment>
);

export { GymnastikPage };
