import { GalleriesPage } from './GalleriesPage';
import { getTripGalleries2022 } from '../getTripGalleries2022';
import { ReactElement } from 'react';

const Trips2022Page = (): ReactElement => {
  const galleryCollection = getTripGalleries2022();

  return (
    <GalleriesPage galleryCollection={ galleryCollection } title='Ausflüge 2022' />
  );
};

export { Trips2022Page };
