import styled from 'styled-components';

const Paragraph = styled.div`
  margin: auto;
  margin-bottom: 1.2em;
  line-height: 1.4;

  a {
    color: ${(props): any => props.theme.colors.decent};
    text-decoration: none;
  
    :hover {
      color: ${(props): any => props.theme.colors.hilight};
    }
  }

  @media only screen and (min-width: 600px) {
    max-width: 600px;
  }

  @media only screen and (min-width: 960px) {
    max-width: 768px;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 960px;
  }

  @media only screen and (min-width: 1440px) {
    max-width: 1280px;
  }
`;

export { Paragraph };
